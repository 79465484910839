import {
  Wrapper,
  Card,
  Checkbox,
  Notification,
} from '@bt-healthcare/ui-toolkit';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { isTrue } from 'validation/rules';

import type { GrantPermissionProps } from '../types';
import { NotificationWrapper } from './styles';

export const GrantPermission = ({
  register,
  control,
}: GrantPermissionProps) => (
  <Wrapper>
    <Card id="grant-permission-container" borderRadius={8}>
      <Checkbox
        id="grant-permission"
        position="right"
        label="Grant permission to manage other users?"
        {...register('careSettingAdministrator')}
      />
    </Card>
    <ConditionalFieldRenderer
      control={control}
      fieldName="careSettingAdministrator"
      condition={isTrue}
    >
      <NotificationWrapper>
        <Notification type="softWarning" alignSelf="baseline">
          By selecting the field above, you are granting this user access to
          manage all user accounts, access to apps and permissions across the
          system.
        </Notification>
      </NotificationWrapper>
    </ConditionalFieldRenderer>
  </Wrapper>
);
