import { fontSizes, colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const ModalHeadlineWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const ModalHeadline = styled.span`
  font-size: ${fontSizes.h4};
  font-weight: 500;
  line-height: 30px;
  color: ${colors.primaryIndigo.indigo08};
  padding: ${spacing.none} ${spacing.s3};

  @media ${device.tablet} {
    font-size: ${fontSizes.h3};
    line-height: 54px;
    padding: ${spacing.none} ${spacing.s6};
  }
`;

export const CardWrapper = styled.div`
  overflow-y: scroll;
  border-radius: ${spacing.s4};

  @media ${device.desktop} {
    margin: auto;
    width: 90%;
  }
`;

export const SubHeader = styled.h3`
  color: ${colors.grey.grey10};
  font-weight: 500;
  font-size: ${fontSizes.h4};
  text-align: center;
  margin-bottom: ${spacing.s3};

  @media ${device.desktop} {
    font-size: ${fontSizes.h3};
    margin-bottom: ${spacing.s4};
  }
`;
