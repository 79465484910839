import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from '@bt-healthcare/ui-toolkit';
import { shallow } from 'zustand/shallow';

import { ROUTES } from 'App.constants';
import { ConfirmationIllustration } from 'assets/ConfirmationIllustration';
import { StatusFormConfirmation } from 'components/Form/StatusFormConfirmation';
import { useTracking } from 'hooks/useTracking';
import { useEffect } from 'react';
import { useStore } from 'store';
import { userAdmissionFormMapping } from 'mappings/forms/userAdmission';
import { userConfirmationMapping } from 'mappings/datalists/userAdmission';
import { getConfirmationConfig } from './settings.utils';

export const UserRegistrationFormConfirmation = () => {
  useScrollToTop();
  const [formData, userAction] = useStore(
    (state) => [state.userAdmissionFormData, state.userAction],
    shallow
  );

  const isEdit = userAction.userAction === 'edit';
  const isReactivate = userAction.userAction === 'activate';
  const data = userAdmissionFormMapping(formData);
  const navigate = useNavigate();
  const { trackPage } = useTracking();

  const handleClick = () => navigate(ROUTES.SETTINGS_HOME);

  const { title, summary, tracking } = getConfirmationConfig(
    isEdit,
    isReactivate
  );

  useEffect(() => {
    trackPage(tracking);
  }, []);

  return (
    <StatusFormConfirmation
      strapLine={summary}
      data={userConfirmationMapping(data)}
      handleClick={handleClick}
      headline={title}
      buttonText="View users"
      icon={<ConfirmationIllustration />}
    />
  );
};
