import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthUser } from 'auth/useAuthUser';
import { useEffect } from 'react';
import { useHasAccess } from 'auth/useHasAccess';
import type { RoleRouteProps } from './types';

export const RBACRoute = ({ actions, redirectPath }: RoleRouteProps) => {
  const { auth } = useAuthUser();
  const navigate = useNavigate();
  const hasAccess = useHasAccess(actions, auth.userRoles);

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirectPath);
    }
  }, [auth]);

  return <Outlet />;
};
