import { Greeting } from '@bt-healthcare/ui-toolkit';

import { StrapLine } from './styles';

import type { PageHeaderProps } from './types';

export const PageHeader = ({ firstName, strapLine }: PageHeaderProps) => (
  <>
    <Greeting givenName={firstName} />
    <StrapLine data-testid="app-strapline">{strapLine}</StrapLine>
  </>
);
