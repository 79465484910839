import { Header, Button, colors, Text } from '@bt-healthcare/ui-toolkit';

import { DataListCard } from 'components/DataListCard';
import {
  FlexContainer,
  IconWrapper,
  ContentWrapper,
  HeadlineWrapper,
  TextWrapper,
  ViewWardWrapper,
  CardWrapper,
} from './styles';

import type { StatusFormConfirmationProps } from './types';

export const StatusFormConfirmation = ({
  data,
  handleClick,
  headline = 'Update confirmed',
  strapLine,
  buttonText,
  icon,
}: StatusFormConfirmationProps) => (
  <FlexContainer>
    {icon && <IconWrapper>{icon}</IconWrapper>}

    <ContentWrapper>
      <HeadlineWrapper>
        <Header color="indigo08">{headline}</Header>
      </HeadlineWrapper>

      <TextWrapper>
        <Text color={colors.grey.grey08} data-testid="confirmation-text">
          {strapLine}
        </Text>
      </TextWrapper>

      <ViewWardWrapper>
        <Button id="update-action" onClick={handleClick}>
          {buttonText}
        </Button>
        <CardWrapper>
          <DataListCard data={data} />
        </CardWrapper>
      </ViewWardWrapper>
    </ContentWrapper>
  </FlexContainer>
);
