import { useAnalytics } from '@bt-healthcare/ui-toolkit';

import { appName, basePageName } from '../App.constants';

export const useTracking = () => {
  const { trackPage: track } = useAnalytics();

  const trackPage = (pageName: string, careSettingName?: string) => {
    const trackingProperties = {
      appName,
      careSetting: careSettingName,
    };
    track(
      `${pageName}${basePageName}`,
      window.location.href,
      trackingProperties
    );
  };

  return {
    trackPage,
  };
};
