/* eslint-disable react/jsx-no-useless-fragment */
import type { FieldValues } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

import type { ConditionalFieldRendererProps } from './types';

/** A Form item can be wrapped in this components and is rendered based on the condition passed */
export const ConditionalFieldRenderer = <T extends FieldValues>({
  control,
  fieldName,
  defaultValue,
  condition,
  children,
}: ConditionalFieldRendererProps<T>) => {
  const watcher = useWatch({
    control,
    name: fieldName,
    defaultValue,
  });
  return (condition(watcher) ? <>{children}</> : null) as any;
};
