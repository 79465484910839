import {
  updateRegisterCareSettingClinicianInputMap,
  addRegisterCareSettingClinicianInputMap,
} from 'mappings/forms/userAdmission';
import {
  useAddRegisterCareSettingClinicianMutation,
  useUpdateRegisterCareSettingClinicianMutation,
  GetRegisteredCareSettingCliniciansDocument,
} from 'services/graphql';

export const useRegistrationFormSubmission = (
  careSettingId: string,
  mappedUserAdmissionData: any,
  onCompleted: () => void,
  userId: string,
  isEdit: boolean,
  isReactivate: boolean,
  defaultWardId: string
) => {
  const [
    addRegisterCareSettingClinician,
    { loading: addRegisterLoading, error: addRegisterError },
  ] = useAddRegisterCareSettingClinicianMutation({ errorPolicy: 'all' });

  const [
    updateRegisterCareSettingClinician,
    { loading: updateRegisterLoading, error: updateRegisterError },
  ] = useUpdateRegisterCareSettingClinicianMutation({ errorPolicy: 'all' });

  const refetchQueries = [
    {
      query: GetRegisteredCareSettingCliniciansDocument,
      variables: { careSettingId },
    },
  ];

  const onSubmit = () => {
    if (isEdit || isReactivate) {
      updateRegisterCareSettingClinician({
        variables: {
          input: updateRegisterCareSettingClinicianInputMap(
            mappedUserAdmissionData,
            careSettingId,
            userId,
            isReactivate,
            defaultWardId
          ),
        },
        refetchQueries,
        onCompleted,
      });
    } else {
      addRegisterCareSettingClinician({
        variables: {
          input: addRegisterCareSettingClinicianInputMap(
            mappedUserAdmissionData,
            careSettingId,
            defaultWardId
          ),
        },
        refetchQueries,
        onCompleted,
      });
    }
  };

  return {
    loading: addRegisterLoading || updateRegisterLoading,
    error: addRegisterError || updateRegisterError,
    onSubmit,
  };
};
