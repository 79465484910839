import { isEmpty } from 'ramda';

import type { ModuleRole } from 'services/graphql';

export enum RBACAction {
  ADMINISTER_USERS,
}

export const RBACConfig: Partial<Record<ModuleRole, RBACAction[]>> = {
  CARE_SETTING_ADMINISTRATOR: [RBACAction.ADMINISTER_USERS],
};

export const useHasAccess = (
  actions: RBACAction[],
  userRoles?: ModuleRole[]
) => {
  if (!userRoles || isEmpty(userRoles)) return false;
  return userRoles.some((role) => {
    const allowedActions = RBACConfig[role];
    if (!allowedActions) return false;

    return actions.every((action) => allowedActions.includes(action));
  });
};
