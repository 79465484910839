import type { NextLink, Operation } from '@apollo/client';
import type { GraphQLErrors } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { StatusCodes } from 'http-status-codes';
import { logGraphQLError, logNetworkError } from 'sentry';

const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';

export const handleGraphQLErrors = (
  graphQLErrors: GraphQLErrors,
  operation: Operation,
  forward: NextLink
  // eslint-disable-next-line consistent-return
) => {
  const isRetryException = graphQLErrors.some(
    (err) =>
      err.extensions?.code === INTERNAL_SERVER_ERROR &&
      (err.message.includes('ECONNRESET') ||
        err.extensions?.status === StatusCodes.SERVICE_UNAVAILABLE)
  );
  logGraphQLError(graphQLErrors, operation);
  if (isRetryException) return forward(operation);
};

export const errorLink = () =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      logNetworkError(networkError, operation);
    } else if (graphQLErrors) {
      handleGraphQLErrors(graphQLErrors, operation, forward);
    }
  });
