import type {
  MenuBarOptions,
  SideNavMenuOptions,
} from '@bt-healthcare/ui-toolkit';
import {
  Exception,
  SpinnerLoader,
  ModuleAvailability,
  Page,
} from '@bt-healthcare/ui-toolkit';
import { shallow } from 'zustand/shallow';
import { isEmpty } from 'ramda';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useTracking } from 'hooks/useTracking';
import { getMenuConfig, getUIView } from 'modules';
import type { ModuleView } from 'modules/types';
import type { AppRendererProps, Mutable } from 'types';
import { AppRoutes } from 'routes/Routes';
import { PageWrapper } from 'styles';
import { ROUTES, sideNavMainNavItems, topNavMenuItems } from 'App.constants';

import { clearStore, useStore } from 'store';
import { useAuthUser } from 'auth/useAuthUser';
import { RBACAction, useHasAccess } from 'auth/useHasAccess';
import { mapBackRoute } from 'routes/routes.utils';
import { clearSentryUser } from 'sentry';

const AppRenderer = ({ error, modules, firstName }: AppRendererProps) => {
  if (error) return <Exception />;
  if (modules && modules.length > 0) {
    return <AppRoutes firstName={firstName ?? ''} modules={modules} />;
  }
  return <ModuleAvailability />;
};

const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [
    setMenuConfig,
    menuConfig,
    mainNavItems,
    setMainNavItems,
    setAppConfig,
    menuHeader,
  ] = useStore(
    (state) => [
      state.setMenuConfig,
      state.menuConfig,
      state.mainNavItems,
      state.setMainNavItems,
      state.setAppConfig,
      state.menuHeader,
    ],
    shallow
  );

  const topNavItems = topNavMenuItems as unknown as Mutable<MenuBarOptions[]>;
  const isTopNavHidden = (route: string) => route.indexOf('settings') === -1;

  const [uiView, setUIView] = useState<ModuleView>();
  const { auth, user, error } = useAuthUser();
  const { trackPage } = useTracking();
  const hasAccess = useHasAccess([RBACAction.ADMINISTER_USERS], auth.userRoles);

  const handleLogout = () => {
    clearSentryUser();
    clearStore();
    auth.logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    if (uiView === undefined && user.data) {
      const modules = getUIView(user.data);
      setUIView(modules);
      setAppConfig({
        careSettingId:
          user.data.userProfile?.attributes.defaultCareSettingId ?? '',
        organisationName:
          user.data.userProfile?.careSetting?.attributes.name ?? '',
        defaultWardId: user.data.userProfile?.attributes.defaultWardId ?? '',
      });
    }
  }, [user]);

  useEffect(() => {
    if (uiView) {
      setMenuConfig(getMenuConfig(uiView.navItems));
      if (hasAccess) {
        setMainNavItems(sideNavMainNavItems as SideNavMenuOptions[]);
      } else {
        setMainNavItems([]);
      }
    }
  }, [uiView]);

  useEffect(() => {
    const careSettingName =
      user?.data?.userProfile?.careSetting?.attributes?.name;
    if (careSettingName) {
      trackPage('Home', careSettingName);
    }
  }, [user?.data?.userProfile?.careSetting?.attributes?.name]);

  useEffect(() => {
    if (error) {
      trackPage('Home');
    }
  }, [error]);

  const loading = auth.loading || user.loading;

  const handleMenuItemClick = (label: string) => {
    if (label === 'Settings') {
      navigate(ROUTES.SETTINGS_HOME);
    }
    navigate(ROUTES.HOME);
  };

  if (loading || (uiView === undefined && !user.error) || isEmpty(menuConfig))
    return <SpinnerLoader id="loader" data-testid="loader" />;

  return (
    <Page
      profile={user.profile}
      showThemeToggle={false}
      menuConfig={menuConfig}
      onLogout={handleLogout}
      size="wide"
      topNavMenuItems={isTopNavHidden(pathname) ? undefined : topNavItems}
      topNavMenuItemOnClick={handleMenuItemClick}
      menuHeader={isTopNavHidden(pathname) ? undefined : menuHeader}
      mainNavItems={mainNavItems}
      collapsed={false}
      onBackClick={mapBackRoute(pathname, navigate)}
    >
      <PageWrapper>
        <AppRenderer
          error={user.error}
          modules={uiView?.modules}
          firstName={uiView?.firstName}
        />
      </PageWrapper>
    </Page>
  );
};

export default App;
