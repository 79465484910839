import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthUser } from 'auth/useAuthUser';
import { ROUTES } from 'App.constants';
import { useEffect } from 'react';

export const ProtectedRoute = () => {
  const { auth } = useAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthorised) {
      navigate(ROUTES.UNAUTHORISED, { state: { hideButton: true } });
    }
  }, [auth]);

  return <Outlet />;
};
