import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: ${spacing.s6};

  @media ${device.tablet} {
    grid-template-columns: repeat(
      auto-fit,
      minmax(260px, calc(50% - ${spacing.s3}))
    );
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(
      auto-fit,
      minmax(260px, calc(33.33% - ${spacing.s4}))
    );
  }
`;
