import {
  Button,
  Col,
  Grid,
  SpinnerLoader,
  Row,
  SubHeader,
  useScrollToTop,
  ErrorNotification,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { UserSearch } from 'components/UserSearch';
import { useTracking } from 'hooks/useTracking';
import { useStore } from 'store';
import { FETCH_POLICY, ROUTES } from 'App.constants';
import { useGetRegisteredCareSettingCliniciansQuery } from 'services/graphql';
import type { UsersType } from 'types';
import { registeredCliniciansTransform } from 'transforms/registeredCliniciansTransform';
import { SettingsView } from 'components/SettingsView';

export const Settings = () => {
  useScrollToTop();
  const MENU_HEADER = 'Settings';
  const { trackPage } = useTracking();
  const [users, setUsers] = useState<UsersType[]>([]);
  const [filter, setFilter] = useState<UsersType[]>();
  const [
    setMenuHeader,
    setMainNavItem,
    updateMenuConfig,
    menuConfig,
    menuHeader,
    { careSettingId },
    resetUser,
    resetUserAction,
  ] = useStore(
    (state) => [
      state.setMenuHeader,
      state.setMainNavItem,
      state.updateMenuConfig,
      state.menuConfig,
      state.menuHeader,
      state.appConfig,
      state.resetUser,
      state.resetUserAction,
    ],
    shallow
  );

  const { loading, error, refetch } =
    useGetRegisteredCareSettingCliniciansQuery({
      variables: {
        careSettingId,
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const mappedRegisteredUsers = registeredCliniciansTransform(data);
        setUsers(mappedRegisteredUsers);
      },
    });

  useEffect(() => {
    if (!isEmpty(menuConfig) && menuHeader !== MENU_HEADER) {
      setMenuHeader(MENU_HEADER);
      setMainNavItem(MENU_HEADER);
      updateMenuConfig(false);
    }
  }, [menuConfig, menuHeader]);

  const navigate = useNavigate();

  useEffect(() => {
    resetUser();
    resetUserAction();
    trackPage(`settings`);
  }, []);

  const handleClick = () => {
    navigate(ROUTES.SETTINGS_USER);
  };

  if (loading) return <SpinnerLoader id="loader" data-testid="loader" />;
  return (
    <Grid>
      <Row>
        <Col xs={6} alignSelf="center" display={{ xs: 'none', xl: 'block' }}>
          <SubHeader color="indigo08">Users</SubHeader>
        </Col>
      </Row>
      {error ? (
        <Row>
          <Col from={1}>
            <ErrorNotification
              id="clinician-users-list"
              action="trying to display users"
              onTryAgainClick={() => refetch()}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col lg={4} xs={12} alignSelf="center">
              <UserSearch data={users} setFilter={setFilter} />
            </Col>
            <Col
              lg={8}
              xs={12}
              alignSelf="center"
              justifyContent={{ lg: 'right' }}
              order={{ xs: '-1', lg: '1' }}
            >
              <Button id="add-new-user" onClick={handleClick}>
                Add new user
              </Button>
            </Col>
          </Row>
          <Row>
            <Col from={1}>
              <SettingsView users={users} filter={filter} />
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};
