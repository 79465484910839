import { spacing, device, colors } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

const mobileFormFooterHeight = `calc(120px + ${spacing.s2} + ${spacing.s2})`;
const desktopFormFooterHeight = '120px';

export const FormHeader = styled.div`
  display: none;

  @media ${device.desktop} {
    display: block;
    margin-bottom: ${spacing.s7};
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s6};
  padding-bottom: ${mobileFormFooterHeight};

  @media ${device.desktop} {
    max-width: 900px;
    gap: ${spacing.s7};
    padding-bottom: ${desktopFormFooterHeight};
  }
`;

export const FormFooterWrapper = styled.div`
  height: ${mobileFormFooterHeight};
  position: fixed;
  right: 0;
  bottom: 0;
  background: ${colors.base.light};
  box-shadow: 0px 4px 30px 1px rgba(112, 144, 176, 0.15);

  @media ${device.desktop} {
    height: ${desktopFormFooterHeight};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.s4} ${spacing.none};
  gap: ${spacing.s2};
  max-width: 1560px;
  margin: auto;

  @media ${device.desktop} {
    padding: ${spacing.s8} ${spacing.none};
    flex-direction: row;
    gap: ${spacing.s10};

    button {
      height: max-content;
    }

    #form-layout-primary-btn {
      width: 371px;
    }

    #form-layout-tertiary-btn {
      width: fit-content;
      padding: ${spacing.none};
    }
  }
`;
