import type { Operation } from '@apollo/client';
import type { User } from '@auth0/auth0-react';
import type { Profile } from '@bt-healthcare/ui-toolkit';
import { captureException } from '@sentry/react';
import jwt_decode from 'jwt-decode';

import type { GetUserProfileQuery, ModuleRole } from 'services/graphql';
import { HTTP_HEADER } from 'services/httpHeaders';

export const getUserProfile = (
  user?: User,
  data?: GetUserProfileQuery
): Profile => ({
  mail: user?.email || '',
  organisation:
    data?.userProfile?.careSetting?.attributes.name || 'Health Portal',
  organisationShortName:
    data?.userProfile?.careSetting?.attributes.shortName || 'HP',
  displayName:
    data?.userProfile?.careSettingClinician?.attributes.displayName ||
    user?.email ||
    '',
});

export const getUserRoles = (data?: GetUserProfileQuery): ModuleRole[] => {
  if (data?.userProfile?.userModuleRoles == null) return [];
  return data.userProfile.userModuleRoles.flatMap((role) =>
    role?.attributes.moduleRoleId == null ? [] : role.attributes.moduleRoleId
  );
};

export const isAuthorised = (data?: GetUserProfileQuery) =>
  data?.userProfile?.attributes.userState === 'ENABLED';

export const getUserIdFromJwtToken = (
  correlationId: string,
  authToken?: string
) => {
  if (!authToken) return undefined;
  try {
    const decodedToken: any = jwt_decode(authToken);
    return decodedToken['https://aim.bt.com/aim_user_id'];
  } catch (error) {
    captureException(error, {
      tags: {
        'x-correlation-id': correlationId,
      },
    });
    return undefined;
  }
};

export const getCorrelationId = (operation: Operation) => {
  const correlationId =
    operation.getContext().headers?.[HTTP_HEADER.apigwTracking];
  return {
    'x-correlation-id': correlationId ?? 'Not set',
  };
};

export const getUserId = (operation: Operation, correlationId: string) => {
  const authToken = operation.getContext().headers?.authorization;
  return getUserIdFromJwtToken(correlationId, authToken);
};
