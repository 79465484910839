import { v4 as uuidv4 } from 'uuid';

export const HTTP_HEADER = {
  /** Note: This header is required on the BT environment and is unique for each GraphQL request
   On the BE this is mapped to the x-correlation-id */
  apigwTracking: 'APIGW-Tracking-Header',
};

export const getHttpHeaders = () => ({
  [HTTP_HEADER.apigwTracking]: uuidv4(),
});
