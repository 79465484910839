import type { MenuConfig, SideNavMenuOptions } from '@bt-healthcare/ui-toolkit';
import type { NamedSet } from 'zustand/middleware';

import type { AppConfig, MenuItemsType } from 'types';
import type { AppState } from './types';

export const uiSlice = (set: NamedSet<AppState>) => ({
  menuConfig: {} as MenuConfig,
  mainNavItems: [] as SideNavMenuOptions[],
  menuHeader: '',
  appConfig: {} as AppConfig,
  setMenuConfig: (menuConfig: MenuConfig) =>
    set({ menuConfig }, false, 'ui/setMenuConfig'),
  updateMenuConfig: (homeActive: boolean) => {
    set(
      (state) => ({
        menuConfig: { ...state.menuConfig, homeActive },
      }),
      false,
      'ui/updateMenuConfig'
    );
  },
  setMainNavItems: (mainNavItems: SideNavMenuOptions[]) =>
    set({ mainNavItems }, false, 'ui/setMainNavItems'),
  setMainNavItem: (label: MenuItemsType) =>
    set(
      (state) => ({
        mainNavItems: state.mainNavItems.map((item) => ({
          ...item,
          active: item.label === label,
        })),
      }),
      false,
      'ui/setMainNavItem'
    ),
  setMenuHeader: (menuHeader: string) =>
    set({ menuHeader }, false, 'ui/setMenuHeader'),
  setAppConfig: (appConfig: AppConfig) =>
    set({ appConfig }, false, 'ui/setAppConfig'),
});
