import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useScrollToTop } from '@bt-healthcare/ui-toolkit';

import { useStore } from 'store';
import { Modules } from 'components/Modules';
import { PageHeader } from 'components/PageHeader';
import type { AppRoutesProps } from 'routes/types';
import { useTracking } from 'hooks/useTracking';

export const Home = ({ firstName, modules }: AppRoutesProps) => {
  useScrollToTop();
  const MENU_HEADER = 'Home';
  const { trackPage } = useTracking();
  const [
    setMenuHeader,
    setMainNavItem,
    updateMenuConfig,
    resetUser,
    resetUserAction,
  ] = useStore(
    (state) => [
      state.setMenuHeader,
      state.setMainNavItem,
      state.updateMenuConfig,
      state.resetUser,
      state.resetUserAction,
    ],
    shallow
  );

  useEffect(() => {
    resetUser();
    resetUserAction();
    setMenuHeader('');
    setMainNavItem(MENU_HEADER);
    updateMenuConfig(true);
    trackPage('home');
  }, []);

  return (
    <>
      <PageHeader
        firstName={firstName}
        strapLine="Welcome to your home screen allowing you access to all the applications"
      />
      <Modules modules={modules} />
    </>
  );
};
