import {
  Modal,
  Stack,
  SubHeader,
  Button,
  ModalFooter,
} from '@bt-healthcare/ui-toolkit';
import { Strapline, ButtonWrapper } from './DeactivateUserModal.styles';
import type { DeactivateUserModalProps } from './types';

export const DeactivateUserModal = ({
  modalOpen,
  handleClose,
  handleDeactivate,
}: DeactivateUserModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="deactivate-user-modal"
    inPortal
  >
    <Stack space="s4" id="confirmation" alignItems="center">
      <SubHeader>Deactivate user</SubHeader>
      <Strapline textAlign="center">
        You are about to deactivate this user and they will no longer have
        access to any apps.
      </Strapline>
    </Stack>
    <ModalFooter>
      <ButtonWrapper>
        <Button aria-label="Cancel modal" id="cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button id="deactivate" onClick={handleDeactivate} variant="primaryRed">
          Deactivate user
        </Button>
      </ButtonWrapper>
    </ModalFooter>
  </Modal>
);
