import type { SideNavMenuOptions } from '@bt-healthcare/ui-toolkit';

export const appName = 'Health Portal';
export const basePageName = ` - ${appName}`;

export const EMPTY_VALUE = '--';
export const SUPPORT_TEL_NO = '0161 9126600';
export const POLL_INTERVAL = 30000; /** 30 seconds */
export const FETCH_POLICY = {
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
} as const;

export const topNavMenuItems = [
  { label: 'Home', active: false },
  { label: 'Settings', active: true },
] as const;

// Note: Temp solution until the nav has been rebuilt, this has been added to evaluate the current nav item
export type SideNavMenuOptionsWithoutModule = Omit<
  SideNavMenuOptions,
  'module'
>;
export const sideNavMainNavItems: SideNavMenuOptionsWithoutModule[] = [
  {
    path: '/#/settings',
    icon: 'Settings',
    label: 'Settings',
    active: false,
  },
];

export const ROUTES = {
  HOME: '/',
  SETTINGS_HOME: '/settings',
  SETTINGS_USER: '/settings/user',
  SETTINGS_USER_CONFIRM: '/settings/user/confirm',
  UNAUTHORISED: '/unauthorised',
  PAGE_NOT_FOUND: '*',
} as const;
