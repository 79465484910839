import { pick } from 'ramda';

import { ModulesMap, StatusMap } from 'mappings/enums';
import type { UsersType } from 'types';
import type { ModuleIdentifier } from 'services/graphql';

export const modulesTransform = (modules: ModuleIdentifier[]) => {
  if (typeof modules === 'string') return null;
  const transform = modules.map((app) => ModulesMap[app]);
  return transform.join(', ');
};

export const userMapping = (data: UsersType) => ({
  emailAddress: {
    key: 'Email',
    value: data.emailAddress,
  },
  mobileNumber: {
    key: 'Mobile number',
    value: data.mobileNumber,
  },
  modules: {
    key: 'App access',
    value: data.modules.map((module: string) => ModulesMap[module]).join(', '),
  },
  careSettingAdministrator: {
    key: 'Grant permissions',
    value: data.careSettingAdministrator ? 'Yes' : 'No',
  },
  firstName: {
    key: 'First name',
    value: data.firstName,
  },
  surname: {
    key: 'Surname',
    value: data.surname,
  },
  status: {
    key: 'Status',
    value: StatusMap[data.userActivationState ?? 'NONE'],
  },
});

export const mapUserObjectKeys = (
  data: UsersType,
  keys: Array<keyof ReturnType<typeof userMapping>>
) => Object.values(pick(keys, userMapping(data)));
