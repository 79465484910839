import type { UserAction, UsersType } from 'types';
import type { NamedSet } from 'zustand/middleware';

import type { AppState } from './types';

export const userSlice = (set: NamedSet<AppState>) => ({
  user: {} as UsersType,
  userAction: {} as UserAction,
  setUser: (user: UsersType) => set({ user }, false, 'user/setUser'),
  resetUser: () => set({ user: {} as UsersType }, false, 'user/resetUser'),
  setUserAction: (userAction: UserAction) =>
    set({ userAction }, false, 'user/setUserAction'),
  resetUserAction: () =>
    set({ userAction: {} as UserAction }, false, 'user/resetUserAction'),
});
