import type { UsersType } from 'types';
import { mapUserObjectKeys } from './userMapping';

export const userSelectionMapping = (data: UsersType) =>
  mapUserObjectKeys(data, [
    'emailAddress',
    'mobileNumber',
    'modules',
    'careSettingAdministrator',
    'status',
  ]);

export const userConfirmationMapping = (data: UsersType) =>
  mapUserObjectKeys(data, [
    'firstName',
    'surname',
    'emailAddress',
    'mobileNumber',
    'modules',
    'careSettingAdministrator',
  ]);
