import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AddRegisterCareSettingClinicianInput = {
  careSettingAdministrator: Scalars['Boolean'];
  careSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  mobileNumber: Scalars['String'];
  modules: Array<ModuleIdentifier>;
  receiveBedAvailabilitySmsAlerts: Scalars['Boolean'];
  surname: Scalars['String'];
};

export type Audit = {
  __typename?: 'Audit';
  created: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['ID']>;
  createdByUser: Maybe<UserProfile>;
  lastUpdated: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['ID']>;
  updatedByUser: Maybe<UserProfile>;
  version: Scalars['Int'];
};

export type CareSetting = Identifier & {
  __typename?: 'CareSetting';
  attributes: CareSettingAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingAttributes = {
  __typename?: 'CareSettingAttributes';
  contactPoints: Maybe<Array<Maybe<ContactPoint>>>;
  description: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A list of related organisations such as General practices for a PCN */
  relatedOrganisations: Maybe<Array<Maybe<RelatedOrganisation>>>;
  res_phone: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
};

export type CareSettingClinician = Identifier & {
  __typename?: 'CareSettingClinician';
  attributes: CareSettingClinicianAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingClinicianAttributes = {
  __typename?: 'CareSettingClinicianAttributes';
  audit: Maybe<Audit>;
  careSettingClinicianModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  careSettingClinicianModuleRoles: Array<Maybe<UserModuleRole>>;
  careSettingClinicianUser: Maybe<User>;
  careSettingId: Scalars['ID'];
  /** @deprecated Use userId */
  clinicianId: Maybe<Scalars['ID']>;
  /** @deprecated Review usage with contactPoints */
  contactInformation: Maybe<CareSettingClinicianAttributesContactInformation>;
  contactPoints: Maybe<Array<Maybe<ContactPoint>>>;
  displayName: Maybe<Scalars['String']>;
  /** @deprecated Use displayName */
  dnHumanNameTextCurrent: Maybe<Scalars['String']>;
  /** @deprecated Use res_mobileNumber */
  dnPhoneNumberCurrent: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  humanNames: Maybe<Array<Maybe<HumanName>>>;
  receiveBedAvailabilitySmsAlerts: Maybe<Scalars['Boolean']>;
  res_mobileNumber: Maybe<Scalars['String']>;
  specialties: Maybe<Array<Maybe<SpecialtyType>>>;
  surname: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type CareSettingClinicianAttributesContactInformation = {
  __typename?: 'CareSettingClinicianAttributesContactInformation';
  primaryContactNumber: Maybe<Scalars['String']>;
  primaryEmailAddress: Maybe<Scalars['String']>;
};

export type CareSettingClinicianDocument = {
  __typename?: 'CareSettingClinicianDocument';
  data: CareSettingClinician;
};

export type CareSettingClinicianRegistration = Identifier & {
  __typename?: 'CareSettingClinicianRegistration';
  attributes: CareSettingClinicianRegistrationAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingClinicianRegistrationAttributes = {
  __typename?: 'CareSettingClinicianRegistrationAttributes';
  audit: Audit;
  careSettingClinician: CareSettingClinicianDocument;
  moduleRoles: Array<ModuleRole>;
  user: UserDocument;
};

export type CareSettingModule = Identifier & {
  __typename?: 'CareSettingModule';
  attributes: CareSettingModuleAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingModuleAttributes = {
  __typename?: 'CareSettingModuleAttributes';
  availabilityStatus: UserModuleAvailabilityStatus;
  careSettingId: Scalars['ID'];
  moduleId: ModuleIdentifier;
};

export type ContactPoint = {
  __typename?: 'ContactPoint';
  period: Period;
  rank: Maybe<Scalars['Int']>;
  system: ContactPointsSystem;
  use: Maybe<ContactPointsUse>;
  value: Scalars['String'];
};

export enum ContactPointsSystem {
  Email = 'EMAIL',
  Fax = 'FAX',
  Other = 'OTHER',
  Pager = 'PAGER',
  Phone = 'PHONE',
  Sms = 'SMS',
  Url = 'URL',
}

export enum ContactPointsUse {
  Home = 'HOME',
  Mobile = 'MOBILE',
  Old = 'OLD',
  Temp = 'TEMP',
  Work = 'WORK',
}

export enum ErrorCode {
  AttributeNotSet = 'ATTRIBUTE_NOT_SET',
  Generic = 'GENERIC',
  MandatoryHeaderError = 'MANDATORY_HEADER_ERROR',
  NotFoundError = 'NOT_FOUND_ERROR',
  PasswordContainsUserInformation = 'PASSWORD_CONTAINS_USER_INFORMATION',
  PasswordTooCommon = 'PASSWORD_TOO_COMMON',
  PatientDischarged = 'PATIENT_DISCHARGED',
  RegisterGpPatientLinkage = 'REGISTER_GP_PATIENT_LINKAGE',
  Unauthorized = 'UNAUTHORIZED',
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  VersionMismatch = 'VERSION_MISMATCH',
}

export type HumanName = {
  __typename?: 'HumanName';
  family: Maybe<Scalars['String']>;
  given: Maybe<Array<Maybe<Scalars['String']>>>;
  period: Period;
  prefix: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix: Maybe<Array<Maybe<Scalars['String']>>>;
  text: Maybe<Scalars['String']>;
  use: HumanNamesUse;
};

export enum HumanNamesUse {
  Anonymous = 'ANONYMOUS',
  Maiden = 'MAIDEN',
  Nickname = 'NICKNAME',
  Official = 'OFFICIAL',
  Old = 'OLD',
  Temp = 'TEMP',
  Usual = 'USUAL',
}

export type Identifier = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export enum ModuleIdentifier {
  CommandCentre = 'COMMAND_CENTRE',
  HealthyyouClinician = 'HEALTHYYOU_CLINICIAN',
  HealthyyouPatient = 'HEALTHYYOU_PATIENT',
  SmartMessaging = 'SMART_MESSAGING',
}

export enum ModuleRole {
  CareSettingAdministrator = 'CARE_SETTING_ADMINISTRATOR',
  CommandCentreDefaultUser = 'COMMAND_CENTRE_DEFAULT_USER',
  HealthyyouClinicianDefaultUser = 'HEALTHYYOU_CLINICIAN_DEFAULT_USER',
  HealthyyouPatientDefaultUser = 'HEALTHYYOU_PATIENT_DEFAULT_USER',
  SmartMessagingDefaultUser = 'SMART_MESSAGING_DEFAULT_USER',
}

export type Mutation = {
  __typename?: 'Mutation';
  addRegisterCareSettingClinician: Maybe<CareSettingClinicianRegistration>;
  updateRegisterCareSettingClinician: Maybe<CareSettingClinicianRegistration>;
  updateUserState: Maybe<User>;
};

export type MutationAddRegisterCareSettingClinicianArgs = {
  input: AddRegisterCareSettingClinicianInput;
};

export type MutationUpdateRegisterCareSettingClinicianArgs = {
  input: UpdateRegisterCareSettingClinicianInput;
};

export type MutationUpdateUserStateArgs = {
  input: UserStateInput;
};

export type Period = {
  __typename?: 'Period';
  end: Maybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  careSettingModules: Maybe<Array<CareSettingModule>>;
  registeredCareSettingClinicians: Maybe<Array<CareSettingClinician>>;
  userProfile: Maybe<UserProfile>;
};

export type QueryCareSettingModulesArgs = {
  careSettingId: Scalars['ID'];
};

export type QueryRegisteredCareSettingCliniciansArgs = {
  careSettingId: Scalars['ID'];
};

export type QueryUserProfileArgs = {
  safId: Scalars['String'];
};

export enum RegistrationStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type RelatedOrganisation = {
  __typename?: 'RelatedOrganisation';
  externalReferenceId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  relationshipType: RelatedOrganisationRelationshipType;
  type: RelatedOrganisationType;
};

export enum RelatedOrganisationRelationshipType {
  None = 'NONE',
  Pcn = 'PCN',
}

export enum RelatedOrganisationType {
  GeneralPractice = 'GENERAL_PRACTICE',
  None = 'NONE',
}

export enum SpecialtyType {
  Cardiology = 'CARDIOLOGY',
  EarNoseAndThroat = 'EAR_NOSE_AND_THROAT',
  EndocDiabetes = 'ENDOC_DIABETES',
  Gastro = 'GASTRO',
  GeniMedicine = 'GENI_MEDICINE',
  GenIntMedicine = 'GEN_INT_MEDICINE',
  GenSurgery = 'GEN_SURGERY',
  GeriMedicine = 'GERI_MEDICINE',
  MaxFax = 'MAX_FAX',
  Neurology = 'NEUROLOGY',
  None = 'NONE',
  Ortho = 'ORTHO',
  PlasticSurgery = 'PLASTIC_SURGERY',
  RenMedicine = 'REN_MEDICINE',
  RespMedicine = 'RESP_MEDICINE',
  Rhreu = 'RHREU',
  StrokeMedicine = 'STROKE_MEDICINE',
  Urology = 'UROLOGY',
  VascSurgery = 'VASC_SURGERY',
}

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  dateTime: Scalars['DateTime'];
  decision: TermsAndConditionsStatus;
  version: Scalars['String'];
};

export enum TermsAndConditionsStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

export type UpdateRegisterCareSettingClinicianInput = {
  careSettingAdministrator: Scalars['Boolean'];
  careSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  firstName: Scalars['String'];
  mobileNumber: Scalars['String'];
  modules: Array<ModuleIdentifier>;
  receiveBedAvailabilitySmsAlerts: Scalars['Boolean'];
  surname: Scalars['String'];
  userId: Scalars['ID'];
  userState: InputMaybe<UserState>;
};

export type User = Identifier & {
  __typename?: 'User';
  attributes: UserAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserAttributes = {
  __typename?: 'UserAttributes';
  audit: Maybe<Audit>;
  defaultCareSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  deletionRequested: Maybe<Scalars['DateTime']>;
  registrationStatus: Maybe<RegistrationStatus>;
  safId: Scalars['String'];
  termsAndConditions: Maybe<TermsAndConditions>;
  /** @deprecated To be removed in a future release */
  userRegistrationId: Maybe<Scalars['ID']>;
  userState: Maybe<UserState>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  data: User;
};

export type UserModuleAvailability = Identifier & {
  __typename?: 'UserModuleAvailability';
  attributes: UserModuleAvailabilityAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleAvailabilityAttributes = {
  __typename?: 'UserModuleAvailabilityAttributes';
  availabilityStatus: UserModuleAvailabilityStatus;
  careSettingModuleId: Scalars['ID'];
  moduleId: ModuleIdentifier;
  moduleName: Scalars['String'];
  moduleShortName: Scalars['String'];
  userId: Scalars['ID'];
};

export enum UserModuleAvailabilityStatus {
  Accessible = 'ACCESSIBLE',
  Visible = 'VISIBLE',
}

export type UserModuleRole = Identifier & {
  __typename?: 'UserModuleRole';
  attributes: UserModuleRoleAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleRoleAttributes = {
  __typename?: 'UserModuleRoleAttributes';
  careSettingModuleId: Scalars['ID'];
  moduleRoleId: ModuleRole;
  userId: Scalars['ID'];
};

export type UserProfile = Identifier & {
  __typename?: 'UserProfile';
  attributes: UserAttributes;
  careSetting: Maybe<CareSetting>;
  careSettingClinician: Maybe<CareSettingClinician>;
  id: Scalars['ID'];
  type: Scalars['String'];
  userModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  userModuleRoles: Array<Maybe<UserModuleRole>>;
};

export enum UserState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

/** Updates a user state value */
export type UserStateInput = {
  userId: Scalars['ID'];
  userState: UserState;
};

export type AddRegisterCareSettingClinicianMutationVariables = Exact<{
  input: AddRegisterCareSettingClinicianInput;
}>;

export type AddRegisterCareSettingClinicianMutation = {
  __typename?: 'Mutation';
  addRegisterCareSettingClinician: {
    __typename?: 'CareSettingClinicianRegistration';
    attributes: {
      __typename?: 'CareSettingClinicianRegistrationAttributes';
      moduleRoles: Array<ModuleRole>;
      careSettingClinician: {
        __typename?: 'CareSettingClinicianDocument';
        data: { __typename?: 'CareSettingClinician'; id: string };
      };
      user: {
        __typename?: 'UserDocument';
        data: { __typename?: 'User'; id: string };
      };
    };
  } | null;
};

export type UpdateRegisterCareSettingClinicianMutationVariables = Exact<{
  input: UpdateRegisterCareSettingClinicianInput;
}>;

export type UpdateRegisterCareSettingClinicianMutation = {
  __typename?: 'Mutation';
  updateRegisterCareSettingClinician: {
    __typename?: 'CareSettingClinicianRegistration';
    attributes: {
      __typename?: 'CareSettingClinicianRegistrationAttributes';
      moduleRoles: Array<ModuleRole>;
      careSettingClinician: {
        __typename?: 'CareSettingClinicianDocument';
        data: { __typename?: 'CareSettingClinician'; id: string };
      };
      user: {
        __typename?: 'UserDocument';
        data: { __typename?: 'User'; id: string };
      };
    };
  } | null;
};

export type UpdateUserStateMutationVariables = Exact<{
  input: UserStateInput;
}>;

export type UpdateUserStateMutation = {
  __typename?: 'Mutation';
  updateUserState: { __typename?: 'User'; id: string } | null;
};

export type GetCareSettingModulesQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetCareSettingModulesQuery = {
  __typename?: 'Query';
  careSettingModules: Array<{
    __typename?: 'CareSettingModule';
    attributes: {
      __typename?: 'CareSettingModuleAttributes';
      moduleId: ModuleIdentifier;
    };
  }> | null;
};

export type GetRegisteredCareSettingCliniciansQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetRegisteredCareSettingCliniciansQuery = {
  __typename?: 'Query';
  registeredCareSettingClinicians: Array<{
    __typename?: 'CareSettingClinician';
    id: string;
    attributes: {
      __typename?: 'CareSettingClinicianAttributes';
      email: string | null;
      firstName: string | null;
      surname: string | null;
      res_mobileNumber: string | null;
      userId: string;
      careSettingClinicianUser: {
        __typename?: 'User';
        attributes: {
          __typename?: 'UserAttributes';
          userState: UserState | null;
        };
      } | null;
      careSettingClinicianModuleAvailability: Array<{
        __typename?: 'UserModuleAvailability';
        attributes: {
          __typename?: 'UserModuleAvailabilityAttributes';
          moduleId: ModuleIdentifier;
          moduleShortName: string;
          availabilityStatus: UserModuleAvailabilityStatus;
        };
      } | null>;
      careSettingClinicianModuleRoles: Array<{
        __typename?: 'UserModuleRole';
        attributes: {
          __typename?: 'UserModuleRoleAttributes';
          moduleRoleId: ModuleRole;
        };
      } | null>;
    };
  }> | null;
};

export type GetUserProfileQueryVariables = Exact<{
  safId: Scalars['String'];
}>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  userProfile: {
    __typename?: 'UserProfile';
    id: string;
    userModuleAvailability: Array<{
      __typename?: 'UserModuleAvailability';
      attributes: {
        __typename?: 'UserModuleAvailabilityAttributes';
        availabilityStatus: UserModuleAvailabilityStatus;
        moduleId: ModuleIdentifier;
        moduleName: string;
        moduleShortName: string;
        userId: string;
      };
    } | null>;
    careSetting: {
      __typename?: 'CareSetting';
      attributes: {
        __typename?: 'CareSettingAttributes';
        name: string;
        shortName: string;
      };
    } | null;
    careSettingClinician: {
      __typename?: 'CareSettingClinician';
      attributes: {
        __typename?: 'CareSettingClinicianAttributes';
        firstName: string | null;
        displayName: string | null;
      };
    } | null;
    userModuleRoles: Array<{
      __typename?: 'UserModuleRole';
      attributes: {
        __typename?: 'UserModuleRoleAttributes';
        moduleRoleId: ModuleRole;
      };
    } | null>;
    attributes: {
      __typename?: 'UserAttributes';
      defaultCareSettingId: string;
      userState: UserState | null;
      defaultWardId: string;
    };
  } | null;
};

export const AddRegisterCareSettingClinicianDocument = gql`
  mutation AddRegisterCareSettingClinician(
    $input: AddRegisterCareSettingClinicianInput!
  ) {
    addRegisterCareSettingClinician(input: $input) {
      attributes {
        careSettingClinician {
          data {
            id
          }
        }
        moduleRoles
        user {
          data {
            id
          }
        }
      }
    }
  }
`;
export type AddRegisterCareSettingClinicianMutationFn = Apollo.MutationFunction<
  AddRegisterCareSettingClinicianMutation,
  AddRegisterCareSettingClinicianMutationVariables
>;

/**
 * __useAddRegisterCareSettingClinicianMutation__
 *
 * To run a mutation, you first call `useAddRegisterCareSettingClinicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegisterCareSettingClinicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegisterCareSettingClinicianMutation, { data, loading, error }] = useAddRegisterCareSettingClinicianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegisterCareSettingClinicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRegisterCareSettingClinicianMutation,
    AddRegisterCareSettingClinicianMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRegisterCareSettingClinicianMutation,
    AddRegisterCareSettingClinicianMutationVariables
  >(AddRegisterCareSettingClinicianDocument, options);
}
export type AddRegisterCareSettingClinicianMutationHookResult = ReturnType<
  typeof useAddRegisterCareSettingClinicianMutation
>;
export type AddRegisterCareSettingClinicianMutationResult =
  Apollo.MutationResult<AddRegisterCareSettingClinicianMutation>;
export type AddRegisterCareSettingClinicianMutationOptions =
  Apollo.BaseMutationOptions<
    AddRegisterCareSettingClinicianMutation,
    AddRegisterCareSettingClinicianMutationVariables
  >;
export const UpdateRegisterCareSettingClinicianDocument = gql`
  mutation UpdateRegisterCareSettingClinician(
    $input: UpdateRegisterCareSettingClinicianInput!
  ) {
    updateRegisterCareSettingClinician(input: $input) {
      attributes {
        careSettingClinician {
          data {
            id
          }
        }
        moduleRoles
        user {
          data {
            id
          }
        }
      }
    }
  }
`;
export type UpdateRegisterCareSettingClinicianMutationFn =
  Apollo.MutationFunction<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >;

/**
 * __useUpdateRegisterCareSettingClinicianMutation__
 *
 * To run a mutation, you first call `useUpdateRegisterCareSettingClinicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegisterCareSettingClinicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegisterCareSettingClinicianMutation, { data, loading, error }] = useUpdateRegisterCareSettingClinicianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegisterCareSettingClinicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >(UpdateRegisterCareSettingClinicianDocument, options);
}
export type UpdateRegisterCareSettingClinicianMutationHookResult = ReturnType<
  typeof useUpdateRegisterCareSettingClinicianMutation
>;
export type UpdateRegisterCareSettingClinicianMutationResult =
  Apollo.MutationResult<UpdateRegisterCareSettingClinicianMutation>;
export type UpdateRegisterCareSettingClinicianMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >;
export const UpdateUserStateDocument = gql`
  mutation UpdateUserState($input: UserStateInput!) {
    updateUserState(input: $input) {
      id
    }
  }
`;
export type UpdateUserStateMutationFn = Apollo.MutationFunction<
  UpdateUserStateMutation,
  UpdateUserStateMutationVariables
>;

/**
 * __useUpdateUserStateMutation__
 *
 * To run a mutation, you first call `useUpdateUserStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStateMutation, { data, loading, error }] = useUpdateUserStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserStateMutation,
    UpdateUserStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserStateMutation,
    UpdateUserStateMutationVariables
  >(UpdateUserStateDocument, options);
}
export type UpdateUserStateMutationHookResult = ReturnType<
  typeof useUpdateUserStateMutation
>;
export type UpdateUserStateMutationResult =
  Apollo.MutationResult<UpdateUserStateMutation>;
export type UpdateUserStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserStateMutation,
  UpdateUserStateMutationVariables
>;
export const GetCareSettingModulesDocument = gql`
  query GetCareSettingModules($careSettingId: ID!) {
    careSettingModules(careSettingId: $careSettingId) {
      attributes {
        moduleId
      }
    }
  }
`;

/**
 * __useGetCareSettingModulesQuery__
 *
 * To run a query within a React component, call `useGetCareSettingModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareSettingModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareSettingModulesQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetCareSettingModulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >(GetCareSettingModulesDocument, options);
}
export function useGetCareSettingModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >(GetCareSettingModulesDocument, options);
}
export type GetCareSettingModulesQueryHookResult = ReturnType<
  typeof useGetCareSettingModulesQuery
>;
export type GetCareSettingModulesLazyQueryHookResult = ReturnType<
  typeof useGetCareSettingModulesLazyQuery
>;
export type GetCareSettingModulesQueryResult = Apollo.QueryResult<
  GetCareSettingModulesQuery,
  GetCareSettingModulesQueryVariables
>;
export const GetRegisteredCareSettingCliniciansDocument = gql`
  query GetRegisteredCareSettingClinicians($careSettingId: ID!) {
    registeredCareSettingClinicians(careSettingId: $careSettingId) {
      attributes {
        email
        firstName
        surname
        res_mobileNumber
        careSettingClinicianUser {
          attributes {
            userState
          }
        }
        userId
        careSettingClinicianModuleAvailability {
          attributes {
            moduleId
            moduleShortName
            availabilityStatus
          }
        }
        careSettingClinicianModuleRoles {
          attributes {
            moduleRoleId
          }
        }
      }
      id
    }
  }
`;

/**
 * __useGetRegisteredCareSettingCliniciansQuery__
 *
 * To run a query within a React component, call `useGetRegisteredCareSettingCliniciansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredCareSettingCliniciansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredCareSettingCliniciansQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetRegisteredCareSettingCliniciansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >(GetRegisteredCareSettingCliniciansDocument, options);
}
export function useGetRegisteredCareSettingCliniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >(GetRegisteredCareSettingCliniciansDocument, options);
}
export type GetRegisteredCareSettingCliniciansQueryHookResult = ReturnType<
  typeof useGetRegisteredCareSettingCliniciansQuery
>;
export type GetRegisteredCareSettingCliniciansLazyQueryHookResult = ReturnType<
  typeof useGetRegisteredCareSettingCliniciansLazyQuery
>;
export type GetRegisteredCareSettingCliniciansQueryResult = Apollo.QueryResult<
  GetRegisteredCareSettingCliniciansQuery,
  GetRegisteredCareSettingCliniciansQueryVariables
>;
export const GetUserProfileDocument = gql`
  query GetUserProfile($safId: String!) {
    userProfile(safId: $safId) {
      userModuleAvailability {
        attributes {
          availabilityStatus
          moduleId
          moduleName
          moduleShortName
          userId
        }
      }
      careSetting {
        attributes {
          name
          shortName
        }
      }
      careSettingClinician {
        attributes {
          firstName
          displayName
        }
      }
      userModuleRoles {
        attributes {
          moduleRoleId
        }
      }
      attributes {
        defaultCareSettingId
        userState
        defaultWardId
      }
      id
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      safId: // value for 'safId'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export type GetUserProfileQueryHookResult = ReturnType<
  typeof useGetUserProfileQuery
>;
export type GetUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileLazyQuery
>;
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
