import { device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.s6};

  @media ${device.desktop} {
    flex-direction: row-reverse;
    gap: ${spacing.l4};
  }
`;

export const IconWrapper = styled.div`
  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 450px;
`;

export const HeadlineWrapper = styled.div`
  font-size: ${fontSizes.h4};
  margin-bottom: ${spacing.s2};
  text-align: center;

  @media ${device.desktop} {
    font-size: ${fontSizes.h2};
    margin-bottom: ${spacing.s8};
  }
`;

export const TextWrapper = styled.div`
  font-size: ${fontSizes.base};
  text-align: center;
  margin-bottom: ${spacing.s6};

  @media ${device.desktop} {
    font-size: ${fontSizes.h4};
    margin-bottom: ${spacing.s8};
  }
`;

export const ViewWardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin: auto;

  & > button {
    margin-bottom: ${spacing.s6};
  }

  @media ${device.desktop} {
    flex-direction: column-reverse;

    & > div {
      margin-bottom: ${spacing.s8};
    }

    & > button {
      margin-bottom: ${spacing.none};
    }
  }
`;

export const CardWrapper = styled.div`
  width: 90%;
  margin: auto;

  @media ${device.desktop} {
    width: 100%;
  }
`;
