import { Wrapper, SubHeader, Button } from '@bt-healthcare/ui-toolkit';
import {
  FormHeader,
  FormContent,
  FormFooterWrapper,
  ButtonWrapper,
} from './styles';
import type { FormLayoutProps } from './types';

export const FormLayout = ({
  id,
  'data-testid': dataTestId,
  header,
  children,
  primaryButtonClick,
  primaryButtonDisabled = false,
  primaryButtonText = 'Continue',
  cancelButtonClick,
  cancelButtonText = 'Cancel',
}: FormLayoutProps) => (
  <Wrapper data-testid={`form-layout-${dataTestId}`} id={`form-layout-${id}`}>
    <FormHeader>
      {typeof header === 'string' ? (
        <SubHeader color="indigo08">{header}</SubHeader>
      ) : (
        header
      )}
    </FormHeader>
    <FormContent data-testid="form-layout-content">{children}</FormContent>
    <FormFooterWrapper id="sticky-footer" data-testid="sticky-footer">
      <ButtonWrapper>
        <Button
          id="form-layout-primary-btn"
          onClick={primaryButtonClick}
          disabled={primaryButtonDisabled}
        >
          {primaryButtonText}
        </Button>
        {cancelButtonClick && (
          <Button
            id="form-layout-tertiary-btn"
            variant="tertiary"
            onClick={cancelButtonClick}
          >
            {cancelButtonText}
          </Button>
        )}
      </ButtonWrapper>
    </FormFooterWrapper>
  </Wrapper>
);
