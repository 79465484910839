import { ListView, Notification } from '@bt-healthcare/ui-toolkit';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { isEmpty } from 'ramda';

import type { ListViewQueryProps, UsersType } from 'types';
import { UserSelectionModal } from 'components/Modal/UserSelectionModal';
import { DeactivateUserModal } from 'components/Modal/DeactivateUserModal';
import { ROUTES } from 'App.constants';
import { useStore } from 'store';
import {
  GetRegisteredCareSettingCliniciansDocument,
  UserState,
  useUpdateUserStateMutation,
} from 'services/graphql';
import { columnWidthSizes, headerData, rowData } from './UserListViewConfig';

export const UserListView = ({ data }: ListViewQueryProps<UsersType[]>) => {
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);
  const [deactivationModalOpen, setDeactivationModalOpen] = useState(false);
  const navigate = useNavigate();

  const [updateUserState] = useUpdateUserStateMutation();

  const [
    user,
    userAction,
    setUser,
    setUserAction,
    resetUser,
    resetUserAction,
    { careSettingId },
  ] = useStore(
    (state) => [
      state.user,
      state.userAction,
      state.setUser,
      state.setUserAction,
      state.resetUser,
      state.resetUserAction,
      state.appConfig,
    ],
    shallow
  );

  const handleClose = () => {
    resetUser();
    resetUserAction();
    setSelectionModalOpen(false);
    setDeactivationModalOpen(false);
  };

  const handleDeactivate = (userId: string) => {
    updateUserState({
      variables: {
        input: {
          userId,
          userState: UserState.Disabled,
        },
      },
      refetchQueries: [
        {
          query: GetRegisteredCareSettingCliniciansDocument,
          variables: { careSettingId },
        },
      ],
      onCompleted: () => {
        handleClose();
      },
    });
  };

  const handleEdit = () => {
    if (userAction.userAction === 'view') {
      setUserAction({ ...userAction, userAction: 'edit' });
    }
    setSelectionModalOpen(false);
    navigate(ROUTES.SETTINGS_USER);
  };

  useEffect(() => {
    if (!isEmpty(userAction)) {
      const selectedUser = data.find((item) => item.id === userAction.userId);
      if (selectedUser) {
        setUser(selectedUser);
        switch (userAction.userAction) {
          case 'edit':
            handleEdit();
            break;
          case 'deactivate':
            setDeactivationModalOpen(true);
            break;
          case 'activate':
            handleEdit();
            break;
          default: {
            setSelectionModalOpen(true);
          }
        }
      }
    }
  }, [userAction]);

  return (
    <>
      {!isEmpty(user) && (
        <>
          <UserSelectionModal
            data={user}
            modalOpen={selectionModalOpen}
            handleClose={handleClose}
            handleClick={handleEdit}
          />
          <DeactivateUserModal
            modalOpen={deactivationModalOpen}
            handleClose={handleClose}
            handleDeactivate={() => handleDeactivate(user.id!)}
          />
        </>
      )}
      {data.length === 0 ? (
        <Notification
          type="informative"
          data-testid="clinician-users-search-results-notification"
        >
          Search returned no results. Please try again.
        </Notification>
      ) : (
        <ListView
          headerData={headerData}
          rowData={rowData(data)}
          id="user-settings"
          columnWidths={columnWidthSizes}
        />
      )}
    </>
  );
};
