import type { UserAdmissionFormData } from 'components/UserAdmission/types';
import {
  UserState,
  type AddRegisterCareSettingClinicianInput,
  type UpdateRegisterCareSettingClinicianInput,
} from 'services/graphql';
import type { UsersType } from 'types';

export const userAdmissionFormMapping = (data: UserAdmissionFormData) =>
  ({
    firstName: data.firstName,
    surname: data.surname,
    mobileNumber: data.mobileNumber,
    emailAddress: data.emailAddress,
    modules: typeof data.modules === 'string' ? [data.modules] : data.modules,
    careSettingAdministrator: data.careSettingAdministrator,
  } as UsersType);

const registerCoreCareSettingClinicianMap = (
  mappedUserAdmissionData: UsersType
) => ({
  careSettingAdministrator: mappedUserAdmissionData.careSettingAdministrator,
  firstName: mappedUserAdmissionData.firstName,
  surname: mappedUserAdmissionData.surname,
  mobileNumber: mappedUserAdmissionData.mobileNumber,
  modules: mappedUserAdmissionData.modules,
});

export const addRegisterCareSettingClinicianInputMap = (
  mappedUserAdmissionData: UsersType,
  careSettingId: string,
  defaultWardId: string
): AddRegisterCareSettingClinicianInput => ({
  ...registerCoreCareSettingClinicianMap(mappedUserAdmissionData),
  careSettingId,
  defaultWardId,
  emailAddress: mappedUserAdmissionData.emailAddress,
  receiveBedAvailabilitySmsAlerts: false,
});

export const updateRegisterCareSettingClinicianInputMap = (
  mappedUserAdmissionData: UsersType,
  careSettingId: string,
  userId: string,
  isReactivate: boolean,
  defaultWardId: string
): UpdateRegisterCareSettingClinicianInput => {
  const output = {
    ...registerCoreCareSettingClinicianMap(mappedUserAdmissionData),
    careSettingId,
    defaultWardId,
    userId,
    receiveBedAvailabilitySmsAlerts: false,
  };
  return isReactivate
    ? { ...output, userState: UserState.Enabled }
    : (output as any);
};
