import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import type { EnvTypes } from '@bt-healthcare/ui-toolkit';
import { ErrorBoundary } from '@bt-healthcare/ui-toolkit';

import App from 'App';
import { AuthApolloProvider } from 'auth/AuthApolloProvider';
import { AuthProvider } from 'auth/AuthProvider';
import { AuthenticationTemplate } from 'auth/AuthTemplate';
import { initializeSentry } from 'sentry';
import { initializeAnalytics } from 'services/analytics';
import { getEnvVariable } from './env.utils';

initializeSentry(
  getEnvVariable('VITE_SENTRY_DSN'),
  getEnvVariable('VITE_ENV') as EnvTypes
);
initializeAnalytics();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <AuthProvider>
        <AuthenticationTemplate>
          <AuthApolloProvider>
            <Router>
              <App />
            </Router>
          </AuthApolloProvider>
        </AuthenticationTemplate>
      </AuthProvider>
    </ErrorBoundary>
  </StrictMode>
);
