import { AppCard } from '@bt-healthcare/ui-toolkit';

import { Grid } from './styles';

import type { ModulesProps } from './types';

export const Modules = ({ modules }: ModulesProps) => (
  <Grid data-testid="apps">
    {modules.map(({ path, shortName, label, icon, strapLine, isActive }) => (
      <AppCard
        id={shortName}
        key={shortName}
        link={path ?? ''}
        icon={icon}
        name={label}
        description={strapLine}
        active={isActive}
      />
    ))}
  </Grid>
);
