import { ListViewSubMenu } from 'components/ListViewSubMenu';
import { modulesTransform } from 'mappings/datalists/userMapping';
import { StatusMap } from 'mappings/enums';
import type { UsersType } from 'types';
import { EMPTY_VALUE } from '../../App.constants';

export const columnWidthSizes = {
  desktop: '2fr 2fr 2fr 2fr 2fr 2fr 24px',
  mobile: '2fr 2fr 2fr 24px',
};

export const headerData: any = [
  { id: '1', label: 'First name' },
  { id: '2', label: 'Surname' },
  { id: '3', label: 'Email', hide: 'mobile' },
  { id: '4', label: 'Mobile number', hide: 'mobile' },
  { id: '5', label: 'Access', hide: 'mobile' },
  { id: '6', label: 'Status' },
  { id: '7', label: '' },
];

export const rowData: any = (data: UsersType[]) =>
  data.map((rowItem) => ({
    id: rowItem.id,
    items: [
      {
        id: '1',
        item: rowItem.firstName,
      },
      {
        id: '2',
        item: rowItem.surname,
      },
      {
        id: '3',
        item: rowItem.emailAddress || EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '4',
        item: rowItem.mobileNumber || EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '5',
        item: modulesTransform(rowItem.modules) || EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '6',
        item: StatusMap[rowItem.userActivationState ?? 'NONE'],
      },
      {
        id: '7',
        item: (
          <ListViewSubMenu
            userActivationState={rowItem.userActivationState ?? 'NONE'}
            userId={rowItem.id!}
          />
        ),
        overflow: false,
      },
    ],
  }));
