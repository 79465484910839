import { setContext } from '@apollo/client/link/context';
import type { IdToken } from '@auth0/auth0-react';
import type { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';

import { getHttpHeaders } from 'services/httpHeaders';

export const authLink = (
  getIdTokenClaims: () => Promise<IdToken | undefined>,
  getAccessTokenSilently: (
    options?: any
  ) => Promise<GetTokenSilentlyVerboseResponse>
) =>
  setContext(async (_, { headers, ...rest }) => {
    let token;
    try {
      await getAccessTokenSilently(); // use refreshToken if needed
      token = await getIdTokenClaims();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    const customHttpHeaders = getHttpHeaders();
    const requestHttpHeaders = { ...headers, ...customHttpHeaders };
    if (!token) return { requestHttpHeaders, ...rest };

    return {
      ...rest,
      headers: {
        ...requestHttpHeaders,
        // eslint-disable-next-line no-underscore-dangle
        authorization: `Bearer ${token.__raw}`,
      },
    };
  });
