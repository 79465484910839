import { useClickOutside } from '@bt-healthcare/ui-toolkit';
import type { MutableRefObject } from 'react';
import { useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { UserState } from 'services/graphql';
import { useStore } from 'store';
import type { UserActionType } from 'types';
import {
  ListViewSubMenuWrapper,
  MenuButton,
  Dropdown,
  DropdownListItem,
} from './styles';
import type { ListViewSubMenuProps } from './types';

export const ListViewSubMenu = ({
  userActivationState,
  userId,
}: ListViewSubMenuProps) => {
  const [open, setOpen] = useState(false);

  const [setUserAction] = useStore((state) => [state.setUserAction], shallow);

  const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(dropdownRef, () => setOpen(false));

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAction = (action: UserActionType) => {
    setUserAction({
      userId,
      userAction: action,
    });
    setOpen(false);
  };

  const handleStateChange = (userState: UserState) => {
    setUserAction({
      userId,
      userAction: userState === UserState.Enabled ? 'activate' : 'deactivate',
    });
    setOpen(false);
  };

  return (
    <ListViewSubMenuWrapper ref={dropdownRef}>
      <MenuButton type="button" onClick={handleClick} open={open}>
        <p>...</p>
      </MenuButton>
      {open && (
        <Dropdown>
          <ul>
            {userActivationState === 'ACTIVE' ? (
              <>
                <DropdownListItem
                  onClick={() => handleAction('view')}
                  onKeyPress={() => handleAction('view')}
                >
                  View user
                </DropdownListItem>
                <DropdownListItem
                  onClick={() => handleAction('edit')}
                  onKeyPress={() => handleAction('edit')}
                >
                  Edit user
                </DropdownListItem>
                <DropdownListItem
                  onClick={() => handleStateChange(UserState.Disabled)}
                  onKeyPress={() => handleStateChange(UserState.Disabled)}
                  warning
                >
                  Deactivate
                </DropdownListItem>
              </>
            ) : null}
            {userActivationState === 'DEACTIVATED' ? (
              <DropdownListItem
                onClick={() => handleStateChange(UserState.Enabled)}
                onKeyPress={() => handleStateChange(UserState.Enabled)}
              >
                Reactivate
              </DropdownListItem>
            ) : null}
          </ul>
        </Dropdown>
      )}
    </ListViewSubMenuWrapper>
  );
};
