export const ConfirmationIllustration = () => (
  <svg
    width="425"
    height="419"
    viewBox="0 0 425 419"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.614 175.758C-0.608513 227.385 -32.8294 296.271 91.3409 382.719C175.206 441.123 353.1 432.652 409.792 319.267C466.485 205.882 353.67 28.3232 252.428 28.3232C178.611 28.3232 81.6461 71.3962 26.614 175.758Z"
      fill="#DBE8FF"
    />
    <path
      d="M55.3633 275.054C70.8783 276.999 73.0084 261.82 73.0084 261.82C74.6662 264.951 77.0321 267.652 79.9178 269.708C82.8035 271.764 86.1293 273.118 89.6304 273.661C101.372 275.339 116.283 270.189 126.447 260.495C136.612 250.8 149.929 233.909 157.98 228.441C166.031 222.973 173.948 218.26 180.087 216.7C180.087 216.7 177.504 212.457 181.546 207.458C185.589 202.46 193.002 201.554 193.002 201.554L190.973 210.176C190.973 210.176 201.489 215.442 211.62 213.379C221.751 211.316 222.439 208.901 222.439 208.901L223.244 200.85C223.244 200.85 231.144 202.41 233.308 206.033C235.471 209.656 236.662 212.239 235.589 214.419C241.956 217.14 248.074 220.412 253.871 224.198C263.029 230.253 274.418 245.869 291.56 260.377C308.702 274.886 315.428 279.7 325.911 278.123C336.394 276.546 345.099 266.13 348.756 256.318C348.756 256.318 353.436 269.116 370.41 267.539C367.724 279.966 363.41 291.984 357.579 303.282C353.794 310.604 348.231 316.857 341.399 321.468C334.567 326.078 326.687 328.897 318.481 329.666C304.928 330.857 277.471 326.848 277.471 326.848C277.471 326.848 283.878 354.406 286.243 360.512C288.608 366.617 291.493 372.806 291.493 372.806C270.815 375.173 250.01 376.243 229.198 376.01C196.675 375.473 162.928 375.423 150.533 372.857C150.533 372.857 147.916 353.517 146.407 341.424C144.897 329.331 145.384 304.205 145.384 304.205C145.384 304.205 131.496 318.093 116.87 318.63C102.244 319.166 82.0994 310.277 73.8135 302.377C65.5277 294.477 55.3633 275.054 55.3633 275.054Z"
      fill="white"
    />
    <path
      d="M111.301 270.189C119.033 273.712 126.816 278.425 134.615 275.573C148.956 270.307 134.85 257.727 144.679 249.944C153.065 243.235 160.747 234.211 156.688 229.616"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M189.161 253.131C180.338 243.554 168.161 244.024 164.505 254.809C160.848 265.594 145.937 282.014 151.975 286.795C158.014 291.575 159.775 280.27 175.642 278.71C191.509 277.15 207.829 273.376 189.161 253.131Z"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.2852 270.206C75.7931 270.022 81.781 272.286 82.9383 282.853C83.8776 291.239 78.1916 296.523 73.1429 301.689"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.6153 313.279C103.821 302.276 107.562 288.371 98.1855 288.12C93.5562 287.986 88.6417 293.537 86.5619 302.813C85.8693 305.207 84.7931 307.473 83.375 309.522"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M300.634 268.009C297.649 272.672 294.378 277.1 290.084 279.314C278.259 285.419 262.66 269.25 254.861 273.728C247.062 278.207 246.575 316.198 266.703 311.032C286.83 305.865 291.996 276.211 315.999 290.988C322.859 295.215 326.817 316.567 341.527 320.961"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145.502 324.165C162.493 320.341 191.946 333.155 204.526 348.519C212.912 358.834 214.808 369.502 222.272 375.876"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M253.066 375.406C250.684 363.464 246.155 353.182 249.275 349.207C254.542 342.498 261.536 361.166 267.373 362.072C278.829 363.866 271.013 329.75 284.146 352.679"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256.639 226.311C256.742 229.319 256.248 232.317 255.186 235.133C254.123 237.948 252.514 240.525 250.45 242.715C244.411 249.257 241.493 263.698 251.959 262.692C262.425 261.685 266.015 250.347 279.165 258.23C283.979 261.098 287.87 260.947 290.453 259.22"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M236.511 254.054C230.741 245.332 227.806 237.516 216.719 240.149C213.843 240.93 211.214 242.435 209.085 244.52C206.955 246.606 205.396 249.202 204.555 252.061C203.714 254.921 203.619 257.948 204.281 260.855C204.942 263.761 206.337 266.449 208.333 268.663C221.936 284.312 219.42 291.692 223.345 296.104C231.848 305.681 246.827 269.737 236.511 254.054Z"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M198.454 296.707C191.175 290.2 185.472 290.636 171.986 292.212C158.501 293.789 151.154 306.385 158.568 313.246C165.982 320.106 167.189 318.412 185.237 312.357C203.285 306.302 204.643 302.209 198.454 296.707Z"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M229.551 345.5C223.613 345.5 217.458 353.886 223.278 358.801C229.098 363.715 247.465 345.399 229.551 345.5Z"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M189.111 375.306C195.535 371.699 196.759 365.393 194.83 357.962C193.271 351.941 185.79 339.931 176.028 337.835C168.245 336.157 150.416 340.535 155.347 356.855C157.662 364.554 159.892 370.274 163.297 373.913"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M359.273 266.265C357.21 275.808 358.367 284.547 365.009 285.956"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M330.541 275.926C331.451 277.528 332.58 278.996 333.896 280.287C338.709 284.95 351.709 292.028 356.086 305.815"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M281.396 324.886C293.926 317.472 290.806 304.071 301.155 299.978C310.85 296.154 323.631 306.402 313.986 328.576"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M179.148 220.642C171.298 227.871 167.742 238.22 176.414 239.78C185.085 241.34 196.994 227.418 196.994 227.418"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M212.225 227.502C221.517 230.454 238.977 239.025 235.12 219.552"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145.334 284.648C137.165 287.616 128.007 291.088 120.309 288.002C112.61 284.916 107.075 310.847 124.854 314.034"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M248.253 314.067C248.253 314.067 240.705 319.804 234.181 317.707C227.656 315.611 216.653 307.358 205.901 314.067C195.15 320.777 207.579 338.875 232.084 338.288C256.589 337.701 267.978 340.753 267.609 332.669"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M352.027 240.166C352.027 240.166 351.44 226.462 347.129 220.273C342.819 214.084 356.069 217.187 358.434 227.167C361.144 220.358 365.605 214.386 371.366 209.857C377.824 204.825 377.505 209.505 376.885 211.098"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M367.91 221.85C367.91 221.85 375.223 212.188 379.651 208.599C384.079 205.01 386.746 206.603 385.924 208.263C385.102 209.924 383.241 211.333 379.769 217.17"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M373.094 223.51C373.094 223.51 385.288 211.451 389.078 209.169C392.869 206.888 393.792 208.314 393.037 210.31C392.282 212.306 388.776 215.811 386.16 219.854"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M241.543 308.146C241.543 308.146 266.065 335.688 314.723 330.387C353.821 326.127 361.822 297.596 369.202 272.605C372.389 261.786 374.066 245.868 379.87 236.476C385.673 227.083 397.112 218.126 398.42 216.231C399.729 214.335 398.42 212.406 392.282 215.711C387.541 218.655 383.166 222.151 379.249 226.127"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M375.207 232.064C375.207 232.064 367.374 230.169 358.434 238.874"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M235.623 214.319C256.505 220.759 266.083 237.348 283.98 253.45C307.847 274.936 318.683 284.849 335.305 273.913C348.723 265.057 352.598 246.053 352.078 240.166"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.2598 223.342C35.3205 221.933 34.029 217.354 41.4091 220.994C47.9835 224.231 53.5893 229.142 57.6621 235.234C57.8969 224.986 70.2083 219.199 67.273 226.143C64.3377 233.087 66.6356 246.623 66.6356 246.623C71.0637 264.704 82.4861 275.288 96.458 274.013C110.43 272.739 125.442 264.47 140.269 246.69C150.685 234.211 161.017 222.923 180.155 216.667"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.2785 232.014C47.2785 232.014 38.1205 224.08 33.1221 221.481C28.1238 218.881 25.742 220.994 26.8993 222.453C28.0567 223.913 30.2539 224.902 34.7826 229.867"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.5662 234.798C42.5662 234.798 28.1247 225.523 23.9315 224.097C19.7382 222.672 19.1512 224.231 20.3085 226.026C21.4658 227.821 25.6255 230.521 29.0137 233.943"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.1657 238.539C32.5102 235.461 27.5045 232.95 22.2545 231.058C15.5453 229.079 14.6731 231.242 16.3504 232.836C18.0277 234.429 31.0939 240.82 38.7088 248.787C46.3237 256.754 52.4626 269.065 59.1215 282.433C74.7203 313.682 112.996 333.071 144.664 305.916"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.2812 243.52C42.2812 243.52 49.5272 240.032 60.0941 246.69"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M150.567 250.112C143.645 290.576 143.572 331.915 150.349 372.404"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M278.678 328.257C278.678 328.257 282.519 356.604 291.526 372.404"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M147.128 348.854C106.789 355.564 98.9726 370.072 165.209 374.014C259.708 379.583 311.721 372.605 326.9 364.319C341.61 356.302 305.766 349.811 282.368 347.144"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M176.029 218.143C171.584 222.504 156.538 245.969 170.695 274.148"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M236.511 214.855C245.417 218.663 245.921 233.708 226.447 261.182"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M176.027 293.504C181.78 293.504 186.443 288.84 186.443 283.088C186.443 277.335 181.78 272.672 176.027 272.672C170.275 272.672 165.611 277.335 165.611 283.088C165.611 288.84 170.275 293.504 176.027 293.504Z"
      fill="#DBE8FF"
    />
    <path
      d="M176.027 293.504C181.78 293.504 186.443 288.84 186.443 283.088C186.443 277.335 181.78 272.672 176.027 272.672C170.275 272.672 165.611 277.335 165.611 283.088C165.611 288.84 170.275 293.504 176.027 293.504Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M176.029 289.327C179.475 289.327 182.268 286.534 182.268 283.088C182.268 279.642 179.475 276.848 176.029 276.848C172.583 276.848 169.789 279.642 169.789 283.088C169.789 286.534 172.583 289.327 176.029 289.327Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M229.803 309.438C229.154 309.137 228.647 308.598 228.388 307.932C228.128 307.266 228.136 306.525 228.41 305.865C229.199 303.433 230.004 301.068 230.775 298.77C234.13 288.874 237.049 280.32 234.935 274.768C233.61 271.246 230.239 268.646 224.603 266.851C218.967 265.057 214.724 265.174 211.604 267.288C206.656 270.642 204.039 279.247 201.037 289.243C200.333 291.575 199.611 293.957 198.84 296.389C198.804 296.755 198.688 297.108 198.498 297.423C198.308 297.738 198.051 298.006 197.744 298.209C197.437 298.411 197.089 298.543 196.725 298.593C196.361 298.644 195.99 298.612 195.639 298.501C195.289 298.39 194.968 298.202 194.7 297.95C194.431 297.699 194.223 297.391 194.089 297.048C193.955 296.706 193.9 296.338 193.926 295.971C193.953 295.605 194.062 295.249 194.244 294.929C195.015 292.531 195.72 290.183 196.408 287.902C199.762 276.613 202.748 266.868 209.591 262.272C213.986 259.32 219.504 258.918 226.364 261.182C233.224 263.447 237.552 266.885 239.43 271.85C242.332 279.565 239.044 289.21 235.254 300.364C234.499 302.611 233.711 304.943 232.939 307.341C232.786 308.027 232.374 308.627 231.79 309.017C231.206 309.408 230.495 309.558 229.803 309.438V309.438Z"
      fill="#DBE8FF"
    />
    <path
      d="M229.803 309.438C229.154 309.137 228.647 308.598 228.388 307.932C228.128 307.266 228.136 306.525 228.41 305.865C229.199 303.433 230.004 301.068 230.775 298.77C234.13 288.874 237.049 280.32 234.935 274.768C233.61 271.246 230.239 268.646 224.603 266.851C218.967 265.057 214.724 265.174 211.604 267.288C206.656 270.642 204.039 279.247 201.037 289.243C200.333 291.575 199.611 293.957 198.84 296.389C198.804 296.755 198.688 297.108 198.498 297.423C198.308 297.738 198.051 298.006 197.744 298.209C197.437 298.411 197.089 298.543 196.725 298.593C196.361 298.644 195.99 298.612 195.639 298.501C195.289 298.39 194.968 298.202 194.7 297.95C194.431 297.699 194.223 297.391 194.089 297.048C193.955 296.706 193.9 296.338 193.926 295.971C193.953 295.605 194.062 295.249 194.244 294.929C195.015 292.531 195.72 290.183 196.408 287.902C199.762 276.613 202.748 266.868 209.591 262.272C213.986 259.32 219.504 258.918 226.364 261.182C233.224 263.447 237.552 266.885 239.43 271.85C242.332 279.565 239.044 289.21 235.254 300.364C234.499 302.611 233.711 304.943 232.939 307.341C232.786 308.027 232.374 308.627 231.79 309.017C231.206 309.408 230.495 309.558 229.803 309.438V309.438Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M195.702 298.536C193.597 305.191 192.038 312.006 191.039 318.915"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M229.802 309.438C227.625 315.914 224.939 322.206 221.768 328.257"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M193.07 201.521C193.07 201.521 182.687 202.292 179.937 210.662C177.186 219.032 187.082 226.496 205.432 227.183C223.781 227.871 238.005 218.143 235.774 210.511C233.107 201.353 222.641 201.135 222.641 201.135"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M186.762 153.617C186.762 153.617 177.202 176.714 183.525 189.427C189.849 202.141 214.639 196.724 218.564 178.341C218.564 178.341 217.407 205.63 193.405 200.145"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M193.807 196.355C193.329 201.029 192.402 205.646 191.039 210.142C191.039 210.142 210.009 218.814 222.505 208.867C222.505 208.867 222.153 190.132 226.313 176.999C226.313 176.999 233.793 176.177 234.481 170.508C235.169 164.838 227 160.813 222.841 171.329C222.841 171.329 221.901 162.205 217.658 158.431"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M230.523 175.758C231.026 180.974 232.787 186.576 223.311 192.53"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M224.77 156.284C217.082 159.058 208.782 159.679 200.768 158.079C186.813 155.496 174.602 149.458 177.755 135.335C180.456 123.191 196.105 123.392 196.105 123.392C196.105 123.392 192.012 129.565 199.04 130.89C210.529 133.054 231.697 127.099 226.514 147.663C226.514 147.663 235.79 141.323 238.809 149.91C241.442 157.358 237.232 161.148 233.324 166.683"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M233.308 166.532C236.763 161.836 241.426 157.291 238.793 149.759C235.773 141.256 226.448 147.579 226.448 147.579C231.631 127.082 210.463 133.037 198.974 130.806C191.946 129.481 196.038 123.309 196.038 123.309C196.038 123.309 180.389 123.107 177.689 135.251C174.535 149.374 186.746 155.378 200.701 157.995C206.242 159.013 211.918 159.058 217.474 158.129C220.046 161.888 221.801 166.145 222.623 170.625C222.623 170.625 227.924 160.997 233.308 166.532Z"
      fill="#2A2A2A"
    />
    <path
      d="M230.523 175.758L226.313 177.066C226.313 177.066 223.143 185.788 223.311 192.581C223.311 192.581 232.905 188.606 230.523 175.758Z"
      fill="#2A2A2A"
    />
    <path
      d="M191.04 321.582C192.532 321.582 193.741 320.373 193.741 318.881C193.741 317.39 192.532 316.181 191.04 316.181C189.549 316.181 188.34 317.39 188.34 318.881C188.34 320.373 189.549 321.582 191.04 321.582Z"
      fill="#2A2A2A"
    />
    <path
      d="M221.835 330.958C223.327 330.958 224.536 329.749 224.536 328.257C224.536 326.766 223.327 325.557 221.835 325.557C220.344 325.557 219.135 326.766 219.135 328.257C219.135 329.749 220.344 330.958 221.835 330.958Z"
      fill="#2A2A2A"
    />
    <path
      d="M194.176 167.338C191.888 169.522 189.48 171.577 186.963 173.493C184.045 175.54 191.593 177.804 194.662 176.647"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M199.459 169.921C199.459 169.921 203.87 178.961 213.565 172.621"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M189.278 168.612C189.278 168.612 184.364 171.967 183.156 167.723"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.18 179.85C184.18 179.85 188.943 187.499 196.407 182.517C196.407 182.517 190.067 193.805 184.18 179.85Z"
      fill="white"
    />
    <path
      d="M184.18 179.85C184.18 179.85 188.943 187.499 196.407 182.517C196.407 182.517 190.067 193.805 184.18 179.85Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.0755 261.836C73.0755 261.836 70.9454 277.016 55.3633 275.053"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.5938 279.364C57.5938 279.364 73.31 284.916 77.5368 267.17"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M348.789 256.285C348.789 256.285 355.28 269.988 370.443 267.506"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M346.273 262.038C346.273 262.038 353.217 278.593 368.934 273.208"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6899 129.531L1.00221 133.506C0.978723 134.337 1.14301 135.162 1.4828 135.92C1.8226 136.678 2.32914 137.349 2.96465 137.884L64.0015 193.067C65.4229 194.29 67.2004 195.022 69.0705 195.155C70.9407 195.288 72.8039 194.814 74.3839 193.805L116.132 164.352C117.258 163.601 118.056 162.45 118.363 161.131L118.916 157.659"
      fill="#2A2A2A"
    />
    <path
      d="M45.7702 96.1363L4.07263 125.59C3.35946 126.017 2.76342 126.615 2.33782 127.33C1.91222 128.044 1.67033 128.853 1.63377 129.684C1.59722 130.514 1.76715 131.341 2.12835 132.09C2.48956 132.839 3.03078 133.487 3.70362 133.976L64.7404 189.159C66.1606 190.384 67.9384 191.118 69.8092 191.251C71.68 191.384 73.5437 190.909 75.1229 189.897L116.804 160.427C117.518 160.001 118.115 159.404 118.542 158.69C118.97 157.977 119.213 157.168 119.251 156.337C119.29 155.507 119.121 154.679 118.761 153.929C118.402 153.18 117.862 152.531 117.189 152.041L56.1527 96.8575C54.7306 95.635 52.9519 94.9044 51.0811 94.7745C49.2103 94.6445 47.3476 95.1221 45.7702 96.1363V96.1363Z"
      fill="#DBE8FF"
    />
    <path
      d="M42.6667 103.55L49.5772 98.6858C49.9537 98.4194 50.4097 98.2892 50.8701 98.3167C51.3304 98.3441 51.7677 98.5275 52.1099 98.8367L113.13 154.003C113.357 154.207 113.536 154.459 113.652 154.741C113.768 155.023 113.82 155.328 113.802 155.633C113.784 155.937 113.698 156.234 113.55 156.501C113.401 156.767 113.195 156.997 112.945 157.173L70.8956 186.895C70.5165 187.162 70.0577 187.293 69.5945 187.266C69.1312 187.238 68.691 187.054 68.3461 186.744L7.32611 131.561C7.09972 131.356 6.92245 131.103 6.80751 130.82C6.69258 130.537 6.64294 130.232 6.66228 129.927C6.68163 129.622 6.76947 129.326 6.91925 129.06C7.06904 128.794 7.27691 128.565 7.52739 128.391L14.6056 123.359C15.0898 123.006 15.6871 122.845 16.2829 122.906L18.5305 123.124C19.7197 123.247 20.9128 122.931 21.885 122.235L39.4967 109.789C40.0907 109.363 40.5846 108.811 40.9438 108.174C41.303 107.537 41.5189 106.829 41.5765 106.099V105.412C41.6099 105.04 41.725 104.681 41.9135 104.359C42.1019 104.037 42.3591 103.761 42.6667 103.55Z"
      fill="white"
    />
    <path
      d="M45.7702 96.1363L4.07263 125.59C3.35946 126.017 2.76342 126.615 2.33782 127.33C1.91222 128.044 1.67033 128.853 1.63377 129.684C1.59722 130.514 1.76715 131.341 2.12835 132.09C2.48956 132.839 3.03078 133.487 3.70362 133.976L64.7404 189.159C66.1606 190.384 67.9384 191.118 69.8092 191.251C71.68 191.384 73.5437 190.909 75.1229 189.897L116.804 160.427C117.518 160.001 118.115 159.404 118.542 158.69C118.97 157.977 119.213 157.168 119.251 156.337C119.29 155.507 119.121 154.679 118.761 153.929C118.402 153.18 117.862 152.531 117.189 152.041L56.1527 96.8575C54.7306 95.635 52.9519 94.9044 51.0811 94.7745C49.2103 94.6445 47.3476 95.1221 45.7702 96.1363V96.1363Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6899 129.531L1.00221 133.506C0.978723 134.337 1.14301 135.162 1.4828 135.92C1.8226 136.678 2.32914 137.349 2.96465 137.884L64.0015 193.067C65.4229 194.29 67.2004 195.022 69.0705 195.155C70.9407 195.288 72.8039 194.814 74.3839 193.805L116.132 164.352C117.258 163.601 118.056 162.45 118.363 161.131L118.916 157.659"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.6667 103.55L49.5772 98.6858C49.9537 98.4194 50.4097 98.2892 50.8701 98.3167C51.3304 98.3441 51.7677 98.5275 52.1099 98.8367L113.13 154.003C113.357 154.207 113.536 154.459 113.652 154.741C113.768 155.023 113.82 155.328 113.802 155.633C113.784 155.937 113.698 156.234 113.55 156.501C113.401 156.767 113.195 156.997 112.945 157.173L70.8956 186.895C70.5165 187.162 70.0577 187.293 69.5945 187.266C69.1312 187.238 68.691 187.054 68.3461 186.744L7.32611 131.561C7.09972 131.356 6.92245 131.103 6.80751 130.82C6.69258 130.537 6.64294 130.232 6.66228 129.927C6.68163 129.622 6.76947 129.326 6.91925 129.06C7.06904 128.794 7.27691 128.565 7.52739 128.391L14.6056 123.359C15.0898 123.006 15.6871 122.845 16.2829 122.906L18.5305 123.124C19.7197 123.247 20.9128 122.931 21.885 122.235L39.4967 109.789C40.0907 109.363 40.5846 108.811 40.9438 108.174C41.303 107.537 41.5189 106.829 41.5765 106.099V105.412C41.6099 105.04 41.725 104.681 41.9135 104.359C42.1019 104.037 42.3591 103.761 42.6667 103.55Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.2341 112.981C30.3272 112.227 29.6649 111.525 28.7547 111.412C27.8445 111.3 27.0312 111.82 26.9381 112.574C26.845 113.328 27.5074 114.03 28.4175 114.142C29.3277 114.255 30.141 113.735 30.2341 112.981Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.627 161.584L57.88 150.095L42.1805 127.166L80.2048 150.313L72.9253 139.461L86.2095 130.068"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M173.461 72.8387L180.891 61.584L204.743 56.5186L209.909 59.001L226.665 81.5438L221.666 89.0917L217.02 78.0215L211.955 85.6868L209.992 70.8594L202.562 82.1141L207.544 59.0513L207.124 58.33L205.095 57.6927L183.491 75.4385L190.821 63.7812L173.461 72.8387Z"
      fill="white"
    />
    <path
      d="M268.197 32.9189L231.766 88.0517L209.558 58.0784L205.499 56.1327L162.074 65.6765L198.505 10.5438L241.93 1L246.006 2.94566L268.197 32.9189Z"
      fill="#DBE8FF"
    />
    <path
      d="M241.929 1L205.498 56.1327"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M245.988 2.94572L209.557 58.0785"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M268.197 32.9189L231.766 88.0517L209.558 58.0784L205.499 56.1327L162.074 65.6765L198.505 10.5438L241.93 1L246.006 2.94566L268.197 32.9189Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M173.461 72.8386L180.891 61.5839"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M183.057 76.0926L191.309 63.5967"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M202.244 82.6005L210.178 70.6078"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.838 85.8377L217.021 78.0047"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M221.668 89.0916L226.666 81.5438"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M205.497 56.1327L173.461 72.8386"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M206.321 56.5186L183.057 76.0926"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M207.125 56.9211L192.65 79.3466"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M207.93 57.3069L202.244 82.6005"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M208.752 57.6927L211.838 85.8377"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M209.557 58.0784L221.432 89.0917"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M291.703 52.4679L271.913 57.4056C269.244 58.0717 267.62 60.7756 268.286 63.445L279.302 107.596C279.968 110.266 282.672 111.89 285.342 111.224L305.131 106.286C307.8 105.62 309.424 102.916 308.758 100.247L297.742 56.0953C297.076 53.4259 294.372 51.8019 291.703 52.4679Z"
      fill="white"
    />
    <path
      d="M291.703 52.4679L271.913 57.4056C269.244 58.0717 267.62 60.7756 268.286 63.445L279.302 107.596C279.968 110.266 282.672 111.89 285.342 111.224L305.131 106.286C307.8 105.62 309.424 102.916 308.758 100.247L297.742 56.0953C297.076 53.4259 294.372 51.8019 291.703 52.4679Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M291.695 52.4594C291.072 52.6643 290.551 53.1 290.239 53.6771C289.927 54.2542 289.848 54.9289 290.018 55.5625V55.5625C290.095 55.873 290.111 56.1959 290.064 56.5126C290.016 56.8292 289.907 57.1334 289.742 57.4077C289.577 57.682 289.359 57.9209 289.101 58.1108C288.843 58.3008 288.551 58.4379 288.24 58.5145L278.075 61.0472C277.446 61.2046 276.78 61.1058 276.223 60.7724C275.667 60.4391 275.265 59.8985 275.107 59.2693V59.2693C274.873 58.6035 274.4 58.048 273.781 57.7103C273.161 57.3726 272.438 57.2766 271.752 57.441"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M293.874 105.546C295.097 105.546 296.088 104.555 296.088 103.332C296.088 102.109 295.097 101.118 293.874 101.118C292.651 101.118 291.66 102.109 291.66 103.332C291.66 104.555 292.651 105.546 293.874 105.546Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M387.209 168.134L346.518 114.854L314.566 139.256L355.257 192.536L387.209 168.134Z"
      fill="white"
    />
    <path
      d="M346.273 122.738L341.744 162.742L382.368 167.002L344.998 158.179L346.273 122.738Z"
      fill="#DBE8FF"
    />
    <path
      d="M387.209 168.134L346.518 114.854L314.566 139.256L355.257 192.536L387.209 168.134Z"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M346.508 114.855L344.998 158.179L387.216 168.143"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M345.419 146.539L314.557 139.26"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M355.248 192.547L356.355 160.863"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M136.88 36.8774L134.447 36.022C134.026 35.8662 133.578 35.7966 133.129 35.8173C132.68 35.838 132.24 35.9486 131.835 36.1426C131.43 36.3366 131.068 36.61 130.77 36.9465C130.473 37.2831 130.246 37.676 130.103 38.1019L108.818 98.4845L109.808 109.806L117.674 101.604L138.959 41.2216C139.115 40.8003 139.185 40.352 139.164 39.9033C139.143 39.4546 139.033 39.0146 138.839 38.6094C138.645 38.2042 138.371 37.8421 138.035 37.5446C137.698 37.2471 137.305 37.0202 136.88 36.8774Z"
      fill="#DBE8FF"
    />
    <path
      d="M109.808 109.857L108.818 98.5348L130.103 38.1521C130.246 37.7262 130.473 37.3334 130.77 36.9968C131.068 36.6602 131.43 36.3869 131.835 36.1929C132.24 35.9989 132.68 35.8883 133.129 35.8676C133.578 35.8468 134.026 35.9165 134.447 36.0723L136.88 36.9277C137.305 37.0705 137.698 37.2974 138.035 37.5949C138.371 37.8924 138.645 38.2545 138.839 38.6597C139.033 39.0648 139.143 39.5048 139.164 39.9535C139.185 40.4023 139.115 40.8506 138.959 41.2719L117.674 101.655L109.808 109.857"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.438 45.6664L136.294 48.7862"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.961 49.8764L134.8 52.9962"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M130.371 51.4363L113.33 99.8766"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.818 98.5348L117.674 101.655"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.875 104.976L113.28 106.183"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.8145 302.377C78.8463 306.687 88.1721 304.222 94.5962 305.731C104.056 307.861 104.509 314.269 119.907 310.293C131.178 307.409 144.429 289.445 147.263 273.728"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M252.613 317.12C252.613 317.12 254.374 331.377 265.109 338.472C275.844 345.567 272.087 363.732 287.082 362.709"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M261.15 321.917C269.537 325.49 280.439 325.607 287.769 324.416C295.975 322.914 304.398 323.068 312.543 324.869C321.533 326.899 328.477 325.171 334.012 318.881C341.879 309.975 350.315 313.547 350.315 313.547"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.8145 302.377C78.3767 306.939 88.1721 304.222 94.5962 305.731C104.056 307.861 104.509 314.269 119.907 310.293C131.178 307.409 144.429 289.445 147.263 273.728C147.263 273.728 145.301 294.879 145.318 305.597C145.318 305.597 130.692 318.932 113.449 318.932C96.2064 318.932 80.3224 310.679 73.8145 302.377Z"
      fill="#2A2A2A"
    />
    <path
      d="M287.082 362.709C272.087 363.732 275.861 345.567 265.109 338.472C254.358 331.377 252.613 317.12 252.613 317.12L261.151 321.917C269.537 325.49 280.44 325.607 287.769 324.416C295.975 322.914 304.398 323.068 312.543 324.869C321.533 326.899 328.477 325.171 334.012 318.881C341.879 309.975 350.316 313.547 350.316 313.547C350.316 313.547 340.47 323.913 329.081 327.335C317.692 330.757 304.459 331.092 295.636 330.84C289.905 330.617 284.216 329.75 278.678 328.257L282.369 347.11C282.369 347.11 297.716 349.106 311.973 351.907C326.23 354.708 330.423 357.945 330.306 360.78C330.188 363.615 321.651 367.053 315.076 368.58C308.501 370.106 291.594 372.773 291.594 372.773L287.082 362.709Z"
      fill="#2A2A2A"
    />
    <path
      d="M147.128 348.855L150.348 372.337C141.625 371.75 132.979 370.312 124.535 368.043C112.559 364.554 115.746 358.683 120.979 356.302C129.307 352.616 138.108 350.11 147.128 348.855V348.855Z"
      fill="#2A2A2A"
    />
    <path
      d="M178.293 217.304C178.293 217.304 178.813 229.85 199.326 232.266C219.84 234.681 239.196 226.16 235.623 214.319"
      stroke="#2A2A2A"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M235.623 214.319C239.196 226.16 219.823 234.681 199.326 232.266C178.83 229.85 178.293 217.304 178.293 217.304L180.155 216.667C180.155 216.667 183.727 225.305 199.31 226.731C214.892 228.156 222.909 224.852 228.377 222.001C231.606 220.352 234.166 217.638 235.623 214.319V214.319Z"
      fill="#2A2A2A"
    />
    <path
      d="M218.279 180.957C218.279 180.957 213.331 192.967 203.016 195.231C199.98 195.843 196.901 196.219 193.808 196.355L193.338 200.078C193.338 200.078 203.184 202.343 209.44 197.78C213.34 194.74 216.09 190.463 217.239 185.654L218.279 180.957Z"
      fill="#2A2A2A"
    />
    <path
      d="M202.715 160.326C206.824 159.84 212.141 162.004 213.097 166.398C213.106 166.47 213.092 166.542 213.058 166.605C213.025 166.669 212.972 166.72 212.908 166.753C212.845 166.786 212.772 166.798 212.701 166.789C212.63 166.78 212.563 166.748 212.51 166.7C211.778 166.102 210.992 165.574 210.162 165.123C207.543 163.902 205 162.524 202.547 160.997C202.485 160.955 202.437 160.894 202.411 160.824C202.384 160.753 202.379 160.677 202.398 160.604C202.416 160.531 202.456 160.465 202.513 160.415C202.569 160.366 202.64 160.335 202.715 160.326Z"
      fill="#2A2A2A"
    />
    <path
      d="M182.972 159.689C184.31 158.346 186.102 157.552 187.996 157.465C189.89 157.378 191.748 158.004 193.203 159.219C193.237 159.252 193.263 159.291 193.281 159.335C193.3 159.378 193.309 159.424 193.309 159.471C193.309 159.518 193.3 159.564 193.281 159.607C193.263 159.651 193.237 159.69 193.203 159.723C191.761 160.108 189.698 159.89 188.171 160.142C186.54 160.326 184.897 160.387 183.257 160.326C183.185 160.322 183.116 160.298 183.057 160.256C182.999 160.214 182.953 160.157 182.925 160.091C182.897 160.025 182.888 159.952 182.899 159.881C182.911 159.81 182.941 159.743 182.988 159.689H182.972Z"
      fill="#2A2A2A"
    />
    <path
      d="M223.311 149.256C223.311 149.256 228.343 134.999 215.025 134.244"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M227.152 149.692C228.661 148.62 230.501 148.119 232.345 148.279C234.188 148.439 235.915 149.249 237.216 150.565"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M179.299 139.679C179.299 139.679 180.255 149.39 195.434 154.439"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M324.972 360.864C316.87 357.325 301.003 354.507 287.518 352.763"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145.334 354.272C133.475 356.335 124.619 359.103 119.939 362.055"
      stroke="#DBE8FF"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
