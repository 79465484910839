import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { uiSlice } from './uiSlice';
import { formSlice } from './formSlice';
import { userSlice } from './userSlice';

import type { AppState } from './types';

const rootSlice: StateCreator<
  AppState,
  [
    ['zustand/persist', unknown],
    ['zustand/devtools', never],
    ['zustand/immer', never]
  ],
  []
> = (set) => ({
  ...uiSlice(set),
  ...formSlice(set),
  ...userSlice(set),
});

export const useStore = create<AppState>()(
  persist(devtools(immer(rootSlice)), { name: 'persist_store' })
);

export const clearStore = () => {
  useStore.persist.clearStorage();
};
