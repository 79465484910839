import { Card, Text, colors } from '@bt-healthcare/ui-toolkit';

import { ContentWrapper, DataListItem } from './styles';
import type { DataListCardProps } from './types';

export const DataListCard = ({
  data,
  background = colors.base.white,
  twoColumns = false,
}: DataListCardProps) => (
  <Card id="data-list" background={background}>
    <ContentWrapper twoColumns={twoColumns}>
      {data.map((item) => (
        <DataListItem key={item.key}>
          <Text color={colors.grey.grey10}>{item.key}:</Text>
          <span>{item.value}</span>
        </DataListItem>
      ))}
    </ContentWrapper>
  </Card>
);
