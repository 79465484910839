import { Routes, Route, useLocation } from 'react-router-dom';
import { Unauthorised, PageNotFound } from '@bt-healthcare/ui-toolkit';

import { Home } from 'pages/Home';
import { Settings } from 'pages/Settings/Settings';
import { UserRegistrationForm } from 'pages/Settings/UserRegistrationForm';
import { UserRegistrationFormConfirmation } from 'pages/Settings/UserRegistrationFormConfirmation';
import { ROUTES } from 'App.constants';
import { RBACAction } from 'auth/useHasAccess';
import type { AppRoutesProps } from './types';
import { RBACRoute } from './RBACRoute';
import { ProtectedRoute } from './ProtectedRoute';

export const AppRoutes = ({ firstName, modules }: AppRoutesProps) => {
  const location = useLocation();
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<ProtectedRoute />}>
        <Route
          path={ROUTES.HOME}
          element={<Home firstName={firstName} modules={modules} />}
        />
        <Route
          element={
            <RBACRoute
              redirectPath={ROUTES.UNAUTHORISED}
              actions={[RBACAction.ADMINISTER_USERS]}
            />
          }
        >
          <Route path={ROUTES.SETTINGS_HOME} element={<Settings />} />
          <Route
            path={ROUTES.SETTINGS_USER}
            element={<UserRegistrationForm />}
          />
          <Route
            path={ROUTES.SETTINGS_USER_CONFIRM}
            element={<UserRegistrationFormConfirmation />}
          />
        </Route>
      </Route>
      <Route
        path={ROUTES.UNAUTHORISED}
        element={<Unauthorised hideButton={location.state?.hideButton} />}
      />
      <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
    </Routes>
  );
};
