import * as yup from 'yup';

export const requireMessage = 'This field is required';

export const isRequired = (isNullable = false, yupSchema = yup.string()) =>
  isNullable
    ? yupSchema.nullable().required(requireMessage)
    : yupSchema.required(requireMessage);

export const isRequiredEmail = () =>
  yup.string().required(requireMessage).email('Valid email address required');

export const isRequiredMultipleChoiceOption = (min = 1) =>
  yup.lazy((val) => {
    if (Array.isArray(val))
      return yup
        .array()
        .min(min)
        .of(yup.string().required())
        .required(requireMessage);
    if (typeof val === 'boolean') {
      return yup.bool().oneOf([true], requireMessage);
    }
    return yup.string().required(requireMessage);
  });

export const isRequiredMobileNumber = () =>
  yup
    .string()
    .required(requireMessage)
    .matches(
      /^\d*$/,
      'Mobile number must be a whole number, no spaces or special characters allowed.'
    )
    .min(10, 'Mobile number must be at least 10 digits.')
    .max(15, 'Mobile number cannot be more than 15 digits.');
