import type { Operation } from '@apollo/client';
import type { EnvTypes } from '@bt-healthcare/ui-toolkit';
import {
  init,
  Replay,
  setUser,
  captureException,
  captureMessage,
} from '@sentry/react';
import { ExtraErrorData } from '@sentry/integrations';

import { getCorrelationId, getUserId } from 'auth/auth.utils';
import { excludeErrorsBeforeSend } from './events';

const needsMask = (ENV: EnvTypes) => ENV === 'staging' || ENV === 'live';

export const initializeSentry = (SENTRY_DSN: string, ENV: EnvTypes) =>
  init({
    dsn: SENTRY_DSN,
    environment: ENV,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    maxValueLength: 2048,
    integrations: [
      new Replay({
        maskAllText: needsMask(ENV),
        blockAllMedia: needsMask(ENV),
      }),
      new ExtraErrorData(),
    ],
    beforeSend(event) {
      return excludeErrorsBeforeSend(event);
    },
  });

export const setSentryUser = (userId: string) => setUser({ id: userId });

export const clearSentryUser = () => setUser(null);

export const logNetworkError = (
  error: unknown,
  operation: Operation,
  retry = false
) => {
  const correlationTag = getCorrelationId(operation);
  const id = getUserId(operation, correlationTag['x-correlation-id']);
  setSentryUser(id);
  captureException(error, {
    tags: {
      type: 'network',
      retry: retry ? 'yes' : 'no',
      ...correlationTag,
    },
    level: 'error',
  });
};

export const logGraphQLError = (error: unknown, operation: Operation) => {
  const correlationTag = getCorrelationId(operation);
  captureMessage(`[GQL]${operation.operationName}`, {
    tags: {
      type: 'graphql',
      operationName: operation.operationName,
      ...correlationTag,
    },
    extra: { errors: JSON.stringify(error, undefined, 2) },
    level: 'error',
  });
};
