import { useAuth0 } from '@auth0/auth0-react';
import { FETCH_POLICY } from 'App.constants';

import { setSentryUser } from 'sentry';
import { useGetUserProfileQuery } from '../services/graphql';
import { getUserProfile, getUserRoles, isAuthorised } from './auth.utils';

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const { data, error, loading } = useGetUserProfileQuery({
    variables: user?.sub ? { safId: user.sub } : undefined,
    skip: !isAuthenticated || !user,
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
  });

  if (data?.userProfile?.id) {
    setSentryUser(data.userProfile.id);
  }
  return {
    user: {
      data,
      loading,
      error,
      profile: getUserProfile(user, data),
    },
    auth: {
      logout,
      loading: isLoading,
      userProfile: user,
      isAuthenticated,
      isAuthorised: isAuthorised(data),
      userRoles: getUserRoles(data),
    },
    error,
  };
};
