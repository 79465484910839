import type { UserAdmissionFormData } from 'components/UserAdmission/types';
import type { NamedSet } from 'zustand/middleware';

import type { AppState } from './types';

export const formSlice = (set: NamedSet<AppState>) => ({
  userAdmissionFormData: {} as UserAdmissionFormData,
  setUserAdmissionForm: (userAdmissionFormData: UserAdmissionFormData) =>
    set({ userAdmissionFormData }, false, 'form/setUserAdmissionForm'),
  resetUserAdmissionFormData: () =>
    set(
      { userAdmissionFormData: {} as UserAdmissionFormData },
      false,
      'form/resetUserAdmissionFormData'
    ),
});
