import 'cross-fetch/polyfill';
import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';

import { retryLink } from './links/retry';
import { authLink } from './links/auth';
import { errorLink } from './links/error';
import { httpLink } from './links/http';

export const AuthApolloProvider = ({ children }: PropsWithChildren<{}>) => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  const client = useRef<ApolloClient<NormalizedCacheObject>>();
  const link = from([
    errorLink(),
    retryLink,
    authLink(getIdTokenClaims, getAccessTokenSilently),
    httpLink,
  ]);

  if (!client.current) {
    client.current = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
