import { device, Paragraph, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Strapline = styled(Paragraph)`
  margin: ${spacing.none} ${spacing.none} ${spacing.s4} ${spacing.none};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};

  @media ${device.desktop} {
    flex-direction: row;
  }
`;
