import {
  ErrorModal,
  ErrorNotification,
  SpinnerLoader,
  useScrollToTop,
} from '@bt-healthcare/ui-toolkit';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'ramda';

import { UserAdmissionFormFields } from 'components/UserAdmission/UserAdmissionFormFields';
import { userAdmissionFormMapping } from 'mappings/forms/userAdmission';
import { useTracking } from 'hooks/useTracking';
import { UserConfirmationModal } from 'components/Modal/UserConfirmationModal';
import { FETCH_POLICY, ROUTES } from 'App.constants';
import type { UserAdmissionFormData } from 'components/UserAdmission/types';
import { useStore } from 'store';
import { userAdmissionSchema } from 'components/UserAdmission/validationSchema';
import { ErrorCode, useGetCareSettingModulesQuery } from 'services/graphql';
import { FormLayout } from 'components/FormLayout';
import { userAlreadyExistsError } from 'services/errors/client';
import { getDefaultValues, getRegistrationFormConfig } from './settings.utils';
import { useRegistrationFormSubmission } from './useRegistrationFormSubmission';

export const UserRegistrationForm = () => {
  useScrollToTop();
  const { trackPage } = useTracking();
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorType, setErrorType] = useState<ErrorCode | undefined>();
  const navigate = useNavigate();

  const [
    formData,
    setFormData,
    { careSettingId, defaultWardId },
    user,
    resetUser,
    userAction,
    setMenuHeader,
  ] = useStore(
    (state) => [
      state.userAdmissionFormData,
      state.setUserAdmissionForm,
      state.appConfig,
      state.user,
      state.resetUser,
      state.userAction,
      state.setMenuHeader,
    ],
    shallow
  );

  const isEdit = !isEmpty(user) && userAction.userAction === 'edit';
  const isReactivate = !isEmpty(user) && userAction.userAction === 'activate';

  const defaultValues = getDefaultValues(isEdit, isReactivate, user);
  const { title, tracking, errorMessage, errorTitle } =
    getRegistrationFormConfig(isEdit, isReactivate, errorType);

  const {
    data: modulesData,
    loading: modulesLoading,
    error: modulesError,
    refetch,
  } = useGetCareSettingModulesQuery({
    variables: {
      careSettingId,
    },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
  });

  const handleClose = () => setModalOpen(false);

  const onCompleted = () => {
    resetUser();
    navigate(ROUTES.SETTINGS_USER_CONFIRM);
  };

  const {
    register,
    control,
    getValues,
    setFocus,
    formState: { errors: formErrors, isValid },
  } = useForm<UserAdmissionFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(userAdmissionSchema),
  });

  const mappedUserAdmissionData = userAdmissionFormMapping(formData);

  const {
    loading: formLoading,
    onSubmit,
    error: formError,
  } = useRegistrationFormSubmission(
    careSettingId,
    mappedUserAdmissionData,
    onCompleted,
    user.id!,
    isEdit,
    isReactivate,
    defaultWardId
  );

  useEffect(() => {
    if (formError) {
      if (userAlreadyExistsError(formError.graphQLErrors)) {
        setErrorType(ErrorCode.UserAlreadyExists);
      } else {
        setErrorType(ErrorCode.Generic);
      }
      setErrorModalOpen(true);
    }
  }, [formError]);

  const handleClick = () => {
    onSubmit();
    handleClose();
  };

  useEffect(() => {
    setMenuHeader(title);
    trackPage(tracking);
  }, []);

  if (modulesLoading)
    return <SpinnerLoader id="content-loader" data-testid="content-loader" />;

  if (modulesError)
    return (
      <ErrorNotification
        id="care-setting-modules"
        action="returning modules associated with the care setting"
        onTryAgainClick={() => refetch()}
      />
    );

  return (
    <>
      <FormLayout
        id="userFormFields"
        data-testid="userFormFields"
        header={title}
        primaryButtonClick={() => {
          const data = getValues();
          setFormData(data);
          setModalOpen(true);
        }}
        primaryButtonDisabled={!isValid}
        cancelButtonClick={() => {
          navigate(ROUTES.SETTINGS_HOME);
        }}
      >
        {formLoading && (
          <SpinnerLoader
            id="form-submission-loader"
            data-testid="form-submission-loader"
            asModal
            text="Submitting user"
          />
        )}
        <UserAdmissionFormFields
          register={register}
          control={control}
          isEdit={isEdit || isReactivate}
          modules={modulesData!}
          errors={formErrors}
        />
      </FormLayout>
      {modalOpen && (
        <UserConfirmationModal
          data={mappedUserAdmissionData}
          modalOpen={modalOpen}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      )}

      {errorModalOpen && (
        <ErrorModal
          isModalOpen={errorModalOpen}
          secondaryButtonText="Cancel"
          onSecondaryAction={() => {
            setErrorModalOpen(false);
            setErrorType(undefined);
          }}
          onPrimaryAction={() => {
            setErrorModalOpen(false);
            if (errorType === ErrorCode.Generic) handleClick();
            if (errorType === ErrorCode.UserAlreadyExists) {
              setFocus('emailAddress');
            }
            setErrorType(undefined);
          }}
          primaryButtonText="Try again"
          title={errorTitle}
        >
          {errorMessage} If the problem persists, please contact us for
          assistance.
        </ErrorModal>
      )}
    </>
  );
};
