import { SearchBar } from '@bt-healthcare/ui-toolkit';
import debounce from 'lodash.debounce';

import { userFilter } from 'filters/userFilter';
import type { ChangeEvent } from 'react';
import { useEffect, useRef } from 'react';
import type { UserSearchProps } from './types';

export const UserSearch = ({ data, setFilter }: UserSearchProps) => {
  const searchValue = useRef('');

  const setUserFilter = (search: string) => {
    if (search === '') {
      searchValue.current = '';
      setFilter(data);
    } else {
      searchValue.current = search;
      const filter = userFilter(data, search);
      setFilter(filter);
    }
  };

  useEffect(() => {
    setUserFilter(searchValue.current);
  }, [data]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setUserFilter(value);
  };

  const debouncedHandleChange = debounce((evt) => handleChange(evt), 1000);

  return (
    <SearchBar
      id="search"
      placeholder="Search users"
      onChange={debouncedHandleChange}
    />
  );
};
