import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const PageWrapper = styled.section`
  margin-top: ${spacing.none};

  @media ${device.desktop} {
    margin-top: ${spacing.s6};
  }
`;
