import { EMPTY_VALUE } from 'App.constants';
import type { EnumMapType, UserActivationState } from 'types';

const EmptyMap = {
  NONE: EMPTY_VALUE,
};

export const getOptionsForEnum = <T extends {}>(value: T, arg?: string) => {
  const entries = Object.entries(value);
  const options = arg ? entries.filter((item) => item[0] !== arg) : entries;
  return options
    .filter((item) => item[0] !== 'NONE')
    .map((item) => ({
      label: item[1] as string,
      value: item[0],
    }));
};

export const StatusMap = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  ...EmptyMap,
} as EnumMapType<UserActivationState>;

export const ModulesMap = {
  HEALTHYYOU_CLINICIAN: 'HealthyYou',
  SMART_MESSAGING: 'Smart messaging',
} as EnumMapType<any>;
