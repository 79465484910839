import { StatusCodes } from 'http-status-codes';
import { ErrorCode } from '../graphql';

const serverErrorCodeValidator = (
  error: any,
  errorCode: ErrorCode,
  statusCode: StatusCodes
) =>
  error?.statusCode === statusCode &&
  error?.result?.errors?.some(
    (item: any) => item.extensions?.code === errorCode
  );

export const isMandatoryHeaderError = (error: any) =>
  serverErrorCodeValidator(
    error,
    ErrorCode.MandatoryHeaderError,
    StatusCodes.BAD_REQUEST
  );
