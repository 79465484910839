import { Notification } from '@bt-healthcare/ui-toolkit';

import { UserListView } from 'components/UserListView/UserListView';
import type { SettingsViewProps } from './types';

export const SettingsView = ({ users, filter }: SettingsViewProps) =>
  users.length === 0 ? (
    <Notification
      type="informative"
      data-testid="clinician-users-empty-notification"
    >
      Current no users have been assigned.
    </Notification>
  ) : (
    <UserListView data={filter ?? users} />
  );
