import { Input, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';

import { moduleOptions } from 'components/Form/form.utils';
import { GrantPermission } from './FormSections';

import type { UserAdmissionFormFieldsProps } from './types';

export const UserAdmissionFormFields = ({
  register,
  control,
  modules,
  isEdit = false,
  errors,
}: UserAdmissionFormFieldsProps) => (
  <>
    <Input
      id="firstName"
      label="First name"
      {...register('firstName')}
      errorText={errors?.firstName?.message ? errors.firstName.message : ''}
    />
    <Input
      id="surname"
      label="Surname"
      {...register('surname')}
      errorText={errors?.surname?.message ? errors.surname.message : ''}
    />
    <Input
      id="email"
      label="Email address"
      {...register('emailAddress')}
      disabled={isEdit}
      helperText={isEdit ? "This field can't be updated" : ''}
      errorText={
        errors?.emailAddress?.message ? errors.emailAddress.message : ''
      }
    />
    <Input
      id="mobileNumber"
      label="Mobile number"
      {...register('mobileNumber')}
      errorText={
        errors?.mobileNumber?.message ? errors.mobileNumber.message : ''
      }
    />
    <MultipleChoiceButton
      label="App access"
      options={moduleOptions(modules)}
      size="lg"
      isMulti
      {...register('modules')}
    />
    <GrantPermission register={register} control={control} />
  </>
);
