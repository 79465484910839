import type { NavigateFunction } from 'react-router';

import { ROUTES } from 'App.constants';

export const mapBackRoute = (pathname: string, navigate: NavigateFunction) => {
  switch (pathname) {
    case ROUTES.SETTINGS_HOME:
      return () => navigate(ROUTES.HOME);
    case ROUTES.SETTINGS_USER:
      return () => navigate(ROUTES.SETTINGS_HOME);
    default:
      return undefined;
  }
};
