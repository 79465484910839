import { ErrorCode } from 'services/graphql';

/** List of GraphQL error codes to ignore  */
export const IGNORE_ERROR_CODES = [ErrorCode.UserAlreadyExists];

export const excludeErrorsBeforeSend = (event: any) => {
  if (event.extra?.errors) {
    const { errors } = event.extra;
    const errorObj = JSON.parse(errors);
    if (Array.isArray(errorObj) && errorObj.length === 1) {
      const ignoreErrors = IGNORE_ERROR_CODES.some((errorCode) =>
        errors.includes(errorCode)
      );
      if (ignoreErrors) return null;
    }
  }
  return event;
};
