import { ModulesMap } from 'mappings/enums';
import type { GetCareSettingModulesQuery } from 'services/graphql';

export const moduleOptions = (data: GetCareSettingModulesQuery) => {
  const modules = data.careSettingModules;

  const results = modules!.map((module) => ({
    label: ModulesMap[module.attributes.moduleId],
    value: module.attributes.moduleId,
  }));

  return results.filter((result) => result.label);
};
