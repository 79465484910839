import * as yup from 'yup';

import {
  isRequiredMultipleChoiceOption,
  isRequired,
  isRequiredEmail,
  isRequiredMobileNumber,
} from 'validation/schema';
import type { FormKey } from '../Form/types';
import type { UserAdmissionFormData } from './types';

const userAdmissionFormRules = {
  firstName: isRequired(),
  surname: isRequired(),
  emailAddress: isRequiredEmail(),
  mobileNumber: isRequiredMobileNumber(),
  modules: isRequiredMultipleChoiceOption(),
} as { [key in FormKey<UserAdmissionFormData>]: any };

export const userAdmissionSchema = yup.object(userAdmissionFormRules);
