import type { UsersType } from 'types';

export const userFilter = (data: UsersType[], value: string) => {
  if (!data || value === '') return data;

  return data.filter(
    (item) =>
      item.firstName?.toLowerCase().includes(value.toLowerCase()) ||
      item.surname?.toLowerCase().includes(value.toLowerCase()) ||
      item.emailAddress?.toLowerCase().includes(value.toLowerCase())
  );
};
