import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@bt-healthcare/ui-toolkit';

import { userSelectionMapping } from 'mappings/datalists/userAdmission';
import { DataListCard } from 'components/DataListCard';
import type { UsersType } from 'types';
import { ModalHeadlineWrapper, ModalHeadline } from './styles';

import type { ModalProps } from './types';

export const UserSelectionModal = ({
  data,
  handleClose,
  handleClick,
  modalOpen,
}: ModalProps<UsersType>) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="user-selection-modal"
    inPortal
  >
    <ModalHeader>
      <ModalHeadlineWrapper>
        <ModalHeadline>
          {data.firstName} {data.surname}
        </ModalHeadline>
      </ModalHeadlineWrapper>
    </ModalHeader>
    <ModalBody>
      <DataListCard data={userSelectionMapping(data)} />
    </ModalBody>
    <ModalFooter flex>
      <Button
        aria-label="Close"
        id="user-selection-modal-close-btn"
        onClick={handleClose}
        variant="secondary"
      >
        Close
      </Button>
      <Button
        aria-label="Edit user"
        id="user-selection-modal-edit-btn"
        onClick={handleClick}
      >
        Edit user
      </Button>
    </ModalFooter>
  </Modal>
);
