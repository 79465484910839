import { ErrorCode } from 'services/graphql';
import type { UsersType } from 'types';

export const getRegistrationFormConfig = (
  isEdit: boolean,
  isReactivate: boolean,
  errorType?: ErrorCode
) => {
  let config = {
    title: 'Add new user',
    tracking: 'add new user',
    errorMessage:
      'The user could not be added. Please review the information you entered and try again.',
    errorTitle: "We couldn't add the user",
  };
  if (isEdit || isReactivate) {
    config = {
      title: `${isEdit ? 'Edit' : 'Reactivate'} user`,
      tracking: `${isEdit ? 'edit' : 'reactivate'} user`,
      errorMessage: `The user could not be ${
        isEdit ? 'updated' : 'reactivated'
      }. Please review the information you entered and try again.`,
      errorTitle: `We couldn't ${isEdit ? 'update' : 'reactivate'} the user`,
    };
  }
  if (errorType === ErrorCode.UserAlreadyExists) {
    return {
      ...config,
      errorTitle: 'Email already registered',
      errorMessage:
        'The email entered is already associated with an existing account. Please use a different email address.',
    };
  }
  return config;
};

export const getConfirmationConfig = (
  isEdit: boolean,
  isReactivate: boolean
) => {
  let config = {
    title: 'Update confirmed',
    summary: 'Your user was successfully added. Find your summary below.',
    tracking: 'add new user confirmation',
  };
  if (isEdit || isReactivate) {
    config = {
      title: isEdit ? config.title : 'User reactivated',
      tracking: `${isEdit ? 'edit' : 'reactivate'} user confirmation`,
      summary: isEdit
        ? 'Your user was successfully updated. Find your update summary below.'
        : 'Your user was successfully reactivated. Find your update summary below.',
    };
  }
  return config;
};

export const getDefaultValues = (
  isEdit: boolean,
  isReactivate: boolean,
  user?: UsersType
) => {
  const defaultValues = {
    firstName: '',
    surname: '',
    emailAddress: '',
    mobileNumber: '',
    modules: null,
    careSettingAdministrator: false,
  };

  return isEdit || isReactivate ? { ...user } : defaultValues;
};
