import Config from './config.json';

type EnvKeys = typeof process.env;
type EnvVars = keyof Pick<
  EnvKeys,
  | 'VITE_ENV'
  | 'VITE_AUTH_CLIENT_ID'
  | 'VITE_AUTH_AUTHORITY'
  | 'VITE_SERVICE_GRAPHQL'
  | 'VITE_MIXPANEL_ANALYTICS_TOKEN'
  | 'VITE_SENTRY_DSN'
>;

export const getEnvVariable = (variable: EnvVars) =>
  Config[variable] && !Config[variable].includes('$VITE_')
    ? Config[variable]
    : import.meta.env[variable]!;
