import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  colors,
  Stack,
  Paragraph,
} from '@bt-healthcare/ui-toolkit';

import { userConfirmationMapping } from 'mappings/datalists/userAdmission';
import { DataListCard } from 'components/DataListCard';
import type { UsersType } from 'types';
import { CardWrapper, SubHeader } from './styles';

import type { ModalProps } from './types';

export const UserConfirmationModal = ({
  data,
  handleClose,
  handleClick,
  modalOpen,
}: ModalProps<UsersType>) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="user-confirmation-modal"
    inPortal
  >
    <ModalBody>
      <Stack space="s1" id="confirmation" alignItems="center">
        <SubHeader>Confirm user details</SubHeader>
        <Paragraph textAlign="center">
          Please review the user details below before confirming
        </Paragraph>
      </Stack>
    </ModalBody>
    <CardWrapper>
      <DataListCard
        data={userConfirmationMapping(data)}
        background={colors.primaryIndigo.indigo01}
      />
    </CardWrapper>
    <ModalFooter flex>
      <Button
        aria-label="Cancel modal"
        id="user-confirmation-modal-cancel-btn"
        onClick={handleClose}
        variant="secondary"
      >
        Cancel
      </Button>
      <Button id="user-confirmation-modal-confirm-btn" onClick={handleClick}>
        Confirm
      </Button>
    </ModalFooter>
  </Modal>
);
